import EmployeeList from "../views/pages/employee/EmployeeList";
import StationList from "../views/pages/station/StationList";
import ManagerList from "../views/pages/manager/ManagerList";
import StationCreate from "../views/pages/station/StationCreate";
import StationDetails from "../views/pages/station/StationDetail";
import StationFm from "../views/pages/station/StationFM";
import StationEdit from "../views/pages/station/StationEdit";
import ClientUserCreate from "../views/pages/client/ClientUserCreate";
import ClientUserList from "../views/pages/client/ClientUserList";
import ClientUserAssignStation from "../views/pages/client/ClientUserAssignStation";
import ClientManagerList from "../views/pages/client/ClientManagerList";
import ClientManagerCreate from "../views/pages/client/ClientManagerCreate";
import ClientEmployeeCreate from "../views/pages/client/ClientEmployeeCreate";
import ClientEmployeeList from "../views/pages/client/ClientEmployeeList";
import ClientManagerAssignStation from "../views/pages/client/ClientManagerAssignStation";
import ClientEmployeeAssignStation from "../views/pages/client/ClientEmployeeAssignStation";
import OnboardingQueue from "views/pages/station/OnboardingQueue";
import CompleteOnboarding from "views/pages/station/CompleteOnboarding";
import SubscriptionManage from "views/pages/station/SubscriptionManage";
import OnboardStation from "views/pages/station/OnboardStation";
import JobberList from "views/pages/jobber/JobberList";
import JobberAssignStation from "views/pages/jobber/JobberAssignStation";
import VendorManagerAssignStation from "views/pages/manager/VendorManagerAssignStation";
import VendorEngineerAssignManager from "views/pages/manager/VendorEngineerAssignManager";
import OTAStation from "views/pages/station/OTAStation";
import ClientEmployeeDetails from "views/pages/client/ClientEmployeeDetails";
import VendorEngineerDetails from "views/pages/employee/VendorEngineerDetails";
import ClientManagerDetail from "views/pages/client/ClientManagerDetail";
import ClientOwnerDetails from "views/pages/client/ClientOwnerDetails";
import VendorManagerDetails from "views/pages/manager/VendorManagerDetails";
import ClientOwnerEdit from "views/pages/client/ClientOwnerEdit";
import ClientManagerEdit from "views/pages/client/ClientManagerEdit";
import ClientEmployeeEdit from "views/pages/client/ClientEmployeeEdit";
import JobberEdit from "views/pages/jobber/JobberEdit";
import JobberDetails from "views/pages/jobber/JobberDetails";
import VendorEngineerEdit from "views/pages/employee/VendorEngineerEdit";
import VendorManagerEdit from "views/pages/manager/VendorManagerEdit";
import GatewayDetail from "views/pages/station/GatewayDetail";
import StationsWithGateway from "views/pages/station/StationsWithGateway";

const admin_routes = [
  {
    collapse: true,
    name: "Station",
    icon: "ni ni-shop text-primary",
    state: "stationCollapse",
    access_level: "1",
    views: [
      {
        path: "/station-list",
        name: "Station List",
        miniName: "SL",
        component: StationList,
        layout: "/admin",
        access_level: "1",
        invisible: false,
      },
      {
        path: "/with-gateway",
        name: "With Gateway",
        miniName: "SWG",
        component: StationsWithGateway,
        layout: "/admin",
        access_level: "1",
        invisible: false,
      },
      {
        path: "/station-create",
        name: "Create Station",
        miniName: "SC",
        component: StationCreate,
        layout: "/admin",
        access_level: "1",
        invisible: false,
      },
      {
        path: "/station-edit",
        name: "Station Edit",
        miniName: "SE",
        component: StationEdit,
        layout: "/admin",
        access_level: "1",
        invisible: true,
      },
      {
        path: "/with-gateway-station-edit",
        name: "Station Edit",
        miniName: "SE",
        component: StationEdit,
        layout: "/admin",
        access_level: "1",
        invisible: true,
      },
      {
        path: "/station-details",
        name: "Station Details",
        miniName: "SD",
        component: StationDetails,
        layout: "/admin",
        access_level: "1",
        invisible: true,
      },
      {
        path: "/with-gateway-station-details",
        name: "Station Details",
        miniName: "SD",
        component: StationDetails,
        layout: "/admin",
        access_level: "1",
        invisible: true,
      },

      {
        path: "/station-files",
        name: "Station Files",
        miniName: "SFM",
        component: StationFm,
        layout: "/admin",
        access_level: "1",
        invisible: true,
      },
      // ---------------------------------
      {
        path: "/onboard-station",
        name: "Automatic Onboard",
        miniName: "OBS",
        component: OnboardStation,
        layout: "/admin",
        access_level: "1",
        invisible: false,
      },
      {
        path: "/onboarding-queue",
        name: "Onboarding Queue",
        miniName: "OBQ",
        component: OnboardingQueue,
        layout: "/admin",
        access_level: "1",
        invisible: false,
      },
      {
        path: "/complete-onboarding",
        name: "Complete Onboarding",
        miniName: "COB",
        component: CompleteOnboarding,
        layout: "/admin",
        access_level: "1",
        invisible: true,
      },
      {
        path: "/subscription-manage",
        name: "Subscription Manage",
        miniName: "SUB",
        component: SubscriptionManage,
        layout: "/admin",
        access_level: "1",
        invisible: true,
      },
      {
        path: "/with-gateway-subscription-manage",
        name: "Subscription Manage",
        miniName: "SUB",
        component: SubscriptionManage,
        layout: "/admin",
        access_level: "1",
        invisible: true,
      },
      {
        path: "/ota-station",
        name: "OTA Station",
        miniName: "OTA",
        component: OTAStation,
        layout: "/admin",
        access_level: "1",
        invisible: false,
      },
      {
        path: "/gateway-detail",
        name: "Gateway Detail",
        miniName: "GWD",
        component: GatewayDetail,
        layout: "/admin",
        access_level: "1",
        invisible: true,
      },
    ],
  },
  {
    collapse: true,
    name: "Client",
    icon: "ni ni-single-02  text-primary",
    state: "clientCollapse",
    access_level: "1",
    views: [
      // client user ----------------------------------------
      {
        path: "/client-user-create",
        name: "Create Owner",
        miniName: "CCU",
        component: ClientUserCreate,
        layout: "/admin",
        access_level: "1",
        invisible: false,
      },
      {
        path: "/client-user-list",
        name: "List Owner",
        miniName: "CUL",
        component: ClientUserList,
        layout: "/admin",
        access_level: "1",
        invisible: false,
      },
      {
        path: "/client-user-edit",
        name: "List Owner Edit",
        miniName: "CUE",
        component: ClientOwnerEdit,
        layout: "/admin",
        access_level: "1",
        invisible: true,
      },
      {
        path: "/client-user-details",
        name: "List Owner Details",
        miniName: "CUD",
        component: ClientOwnerDetails,
        layout: "/admin",
        access_level: "1",
        invisible: true,
      },
      {
        path: "/client-user-assign-station",
        name: "Assign Owner",
        miniName: "AUS",
        component: ClientUserAssignStation,
        layout: "/admin",
        access_level: "1",
        invisible: false,
      },
      // client manager -----------------------------------
      {
        path: "/client-manager-create",
        name: "Create Manager",
        miniName: "CCM",
        component: ClientManagerCreate,
        layout: "/admin",
        access_level: "1",
        invisible: false,
      },
      {
        path: "/client-manager-list",
        name: "List Manager",
        miniName: "CML",
        component: ClientManagerList,
        layout: "/admin",
        access_level: "1",
        invisible: false,
      },
      {
        path: "/client-manager-edit",
        name: "client Manager Edit",
        miniName: "CME",
        component: ClientManagerEdit,
        layout: "/admin",
        access_level: "1",
        invisible: true,
      },
      {
        path: "/client-manager-details",
        name: "client Manager Details",
        miniName: "CMD",
        component: ClientManagerDetail,
        layout: "/admin",
        access_level: "1",
        invisible: true,
      },
      {
        path: "/client-manager-assign-station",
        name: "Assign Manager",
        miniName: "AMS",
        component: ClientManagerAssignStation,
        layout: "/admin",
        access_level: "1",
        invisible: false,
      },
      // client employee -------------------------------------------------
      {
        path: "/client-employee-create",
        name: "Create Employee",
        miniName: "CEM",
        component: ClientEmployeeCreate,
        layout: "/admin",
        access_level: "1",
        invisible: false,
      },

      {
        path: "/client-employee-list",
        name: "List Employee",
        miniName: "CEM",
        component: ClientEmployeeList,
        layout: "/admin",
        access_level: "1",
        invisible: false,
      },
      {
        path: "/client-employee-edit",
        name: "Client Employee Edit",
        miniName: "ED",
        component: ClientEmployeeEdit,
        layout: "/admin",
        access_level: "1",
        invisible: true,
      },
      {
        path: "/client-employee-details",
        name: "Client Employee Details",
        miniName: "ED",
        component: ClientEmployeeDetails,
        layout: "/admin",
        access_level: "1",
        invisible: true,
      },
      {
        path: "/client-employee-assign-station",
        name: "Assign Employee",
        miniName: "AMS",
        component: ClientEmployeeAssignStation,
        layout: "/admin",
        access_level: "1",
        invisible: false,
      },
    ],
  },
  {
    collapse: true,
    name: "Vendor",
    icon: "ni ni-single-02  text-primary",
    state: "manCollapse",
    access_level: "1",
    views: [
      // vendor manager ------------------------------------------------
      {
        path: "/manager-list",
        name: "Vendor Manager List",
        miniName: "EL",
        component: ManagerList,
        layout: "/admin",
        access_level: "1",
        invisible: false,
      },
      {
        path: "/vendor-manager-edit",
        name: "Vendor Manager Edit",
        miniName: "VME",
        component: VendorManagerEdit,
        layout: "/admin",
        access_level: "1",
        invisible: true,
      },
      {
        path: "/vendor-manager-details",
        name: "Vendor Manager Detail",
        miniName: "VMD",
        component: VendorManagerDetails,
        layout: "/admin",
        access_level: "1",
        invisible: true,
      },
      {
        path: "/vendor-manager-assign-station",
        name: "Assign Vendor Manager",
        miniName: "AVM",
        component: VendorManagerAssignStation,
        layout: "/admin",
        access_level: "1",
        invisible: false,
      },
      // vendor employee (engineer) -----------------------------------
      {
        path: "/employee-list",
        name: "Vendor Engineer List",
        miniName: "VEL",
        component: EmployeeList,
        layout: "/admin",
        access_level: "1",
        invisible: false,
      },
      {
        path: "/vendor-engineer-details",
        name: "Vendor Engineer Details",
        miniName: "VED",
        component: VendorEngineerDetails,
        layout: "/admin",
        access_level: "1",
        invisible: true,
      },
      {
        path: "/vendor-engineer-edit",
        name: "Vendor Engineer Edit",
        miniName: "VEE",
        component: VendorEngineerEdit,
        layout: "/admin",
        access_level: "1",
        invisible: true,
      },
      {
        path: "/vendor-engineer-assign-manager",
        name: "Assign Vendor Engineer",
        miniName: "AVE",
        component: VendorEngineerAssignManager,
        layout: "/admin",
        access_level: "1",
        invisible: false,
      },
    ],
  },
  // {
  //   collapse: true,
  //   name: "Employee",
  //   icon: "ni ni-single-02  text-primary",
  //   state: "empCollapse",
  //   access_level: "1",
  //   views: [
  //     {
  //       path: "/employee-list",
  //       name: "Employee List",
  //       miniName: "EL",
  //       component: EmployeeList,
  //       layout: "/admin",
  //       access_level: "1",
  //       invisible: false,
  //     },
  //   ],
  // },

  {
    collapse: true,
    name: "Jobber",
    icon: "ni ni-single-02  text-primary",
    state: "jobberCollapse",
    access_level: "1",
    views: [
      {
        path: "/jobber-list",
        name: "Jobber List",
        miniName: "JBL",
        component: JobberList,
        layout: "/admin",
        access_level: "1",
        invisible: false,
      },
      {
        path: "/jobber-assign-station",
        name: "Assign Jobber",
        miniName: "AJB",
        component: JobberAssignStation,
        layout: "/admin",
        access_level: "1",
        invisible: false,
      },
      {
        path: "/jobber-edit",
        name: "Jobber Edit",
        miniName: "JBL",
        component: JobberEdit,
        layout: "/admin",
        access_level: "1",
        invisible: true,
      },
      {
        path: "/jobber-details",
        name: "Jobber Details",
        miniName: "JBL",
        component: JobberDetails,
        layout: "/admin",
        access_level: "1",
        invisible: true,
      },
    ],
  },

  // {
  //   collapse: true,
  //   name: "Inspection Setting",
  //   icon: "ni ni-single-02  text-primary",
  //   state: "inspectCollapse",
  //   access_level: "1",
  //   views: [
  //     {
  //       path: "/inspection-setting",
  //       name: "Inspection Setting",
  //       miniName: "IS",
  //       component: InspectionSetting,
  //       layout: "/admin",
  //       access_level: "1",
  //       invisible: false,
  //     },
  //   ],
  // },
];
export default admin_routes;
