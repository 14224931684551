import React, { useEffect, useState } from "react";
// react plugin that prints a given react component
// react component for creating dynamic tables
// react component used to create sweet alerts
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  Row,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { adminRoutes } from "../../../endpoints/base.endpoint";
import * as adminEndpoints from "../../../endpoints/admin.endpoint";

function ClientEmployeeEdit() {
  const employee_id = sessionStorage.getItem("client_employee_edit_id");
  const [isActiveModal, setisActiveModal] = useState(false);
  const [accountStatus, setAccountStatus] = useState();

  const [NewClientEmployeeObject, setNewClientEmployeeObject] = useState({
    id: "",
    name: "",
    email: "",
    phone: "",
    isActive: true,
  });
  let newClientEmployeeObjectValue = {};

  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  let Routes;
  let endpoints;

  Routes = adminRoutes;
  endpoints = adminEndpoints;

  // GET SELECTED CLIENT EMPLOYEE DETAIL
  const [
    {
      data: getClientEmployee,
      loading: getClientEmployeeLoading,
      error: getClientEmployeeError,
    },
    refreshEmployee,
  ] = Routes({
    url: endpoints.view_client_employee.path,
    method: endpoints.view_client_employee.method,
    headers: config.header,
    data: {
      id: employee_id,
    },
  });

  // SET SELECTED CLIENT EMPLOYEE DETAIL AS DEFAULT VALUE
  useEffect(() => {
    if (getClientEmployee) {
      setNewClientEmployeeObject({
        id: getClientEmployee._id,
        name: getClientEmployee.name,
        email: getClientEmployee.email,
        phone: getClientEmployee.phone,
        isActive: getClientEmployee.isActive,
      });
    }
  }, [getClientEmployee]);

  useEffect(() => {
    console.log(NewClientEmployeeObject);
  }, [NewClientEmployeeObject]);

  const handleEditCE = (typeofObj, value) => {
    newClientEmployeeObjectValue[typeofObj] = value;
    setNewClientEmployeeObject((NewClientEmployeeObject) => ({
      ...NewClientEmployeeObject,
      ...newClientEmployeeObjectValue,
    }));
    console.log(NewClientEmployeeObject);
  };

  // UPDATE CLIENT EMPLOYEE DETAIL API
  const [
    { loading: updateClientEmployeeLoading, error: updateClientEmployeeError },
    updateClientManager,
  ] = adminRoutes(
    {
      url: endpoints.edit_client_employee.path,
      method: endpoints.edit_jobber.method,
      headers: config.header,
      data: NewClientEmployeeObject,
    },
    { manual: true }
  );

  // ACCOUNT STATUS UPDATE API
  const [{ loading, error }, changeStatus] = adminRoutes(
    {
      url: adminEndpoints.change_client_employee_status.path,
      method: adminEndpoints.change_client_employee_status.method,
      headers: config.header,
      data: {
        id: employee_id,
        status: accountStatus,
      },
    },
    { manual: true }
  );

  // HANDLE SUBMIT BUTTON
  function handleNewClientEmployeeSubmit() {
    updateClientManager()
      .then((res) => {
        if (res.status === 200) {
          console.log(res);
          window.location.href = "/admin/client-employee-list";
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  //TOOGLE MODAL
  function closeModal() {
    if (isActiveModal) {
      setisActiveModal(false);
    } else {
      setisActiveModal(true);
    }
  }

  // HANDLE ACCOUNT ACITVE SUBMIT
  function handleStatusSubmit() {
    console.log("account status", accountStatus);
    closeModal();
    changeStatus()
      .then((res) => {
        console.log("status", res);
        refreshEmployee();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <SimpleHeader name="Client Employee Edit" parentName="Client" />
      <Modal
        className="modal-dialog-centered w-100 h-100"
        backdrop={true}
        size="md"
        centered={true}
        scrollable={true}
        isOpen={isActiveModal}
        toggle={closeModal}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Active/Deactive Account
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <div>
              <Row>
                <Col lg="4">
                  <FormGroup>
                    <div
                      className="mb-2"
                      style={{ fontSize: "0.875rem", fontWeight: "600" }}
                    >
                      Is Active?
                    </div>
                    <label className="custom-toggle">
                      <input
                        defaultChecked={
                          getClientEmployee && getClientEmployee.isActive
                        }
                        type="checkbox"
                        onChange={(e) => setAccountStatus(e.target.checked)}
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="modal-footer">
          <Button color="danger" onClick={handleStatusSubmit}>
            Submit
          </Button>{" "}
          <Button onClick={closeModal}>Cancel</Button>
        </div>
      </Modal>
      <Container className="mt--6" fluid>
        {getClientEmployee && (
          <Card>
            {/* <CardHeader className="bg-transparent d-flex align-items-center justify-content-between">
              <h3 className="mb-0 "> Client Employee Edit</h3>
              <button className="btn btn-warning btn-sm" onClick={closeModal}>
                Status
              </button>
            </CardHeader> */}
            <CardBody>
              <Form>
                <div className="bg-transparent d-flex align-items-center justify-content-between">
                  <a href="./client-employee-list" className="text-white mb-3">
                    <Button size="sm" color="default" type="button">
                      Back
                    </Button>
                  </a>
                  <button
                    className="btn btn-warning btn-sm"
                    onClick={closeModal}
                  >
                    Status
                  </button>
                </div>
                <h6 className="heading-small text-muted mb-4">
                  Employee Basic Information
                </h6>
                <div className="px-lg-4">
                  <Row>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-user-name"
                        >
                          Name
                        </label>
                        <Input
                          defaultValue={getClientEmployee.name}
                          id="input-user-name"
                          rows="6"
                          type={"text"}
                          placeholder={"Name"}
                          onChange={(e) => {
                            handleEditCE("name", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-user-email"
                        >
                          Email
                        </label>
                        <Input
                          defaultValue={getClientEmployee.email}
                          id="input-user-email"
                          type={"text"}
                          placeholder={"ClientEmployee Address"}
                          onChange={(e) => {
                            handleEditCE("email", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-station-phone"
                        >
                          Phone
                        </label>
                        <Input
                          defaultValue={getClientEmployee.phone}
                          id="input-station-phone"
                          rows="6"
                          type={"text"}
                          placeholder={"Phone"}
                          onChange={(e) => {
                            handleEditCE("phone", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </CardBody>
            <CardFooter>
              <Button color="default" onClick={handleNewClientEmployeeSubmit}>
                Update Employee
              </Button>
              <a className="btn btn-danger" href="./client-employee-list">
                Cancel
              </a>
            </CardFooter>
          </Card>
        )}
      </Container>
    </>
  );
}

export default ClientEmployeeEdit;
