import { useEffect, useState } from "react";
// react plugin that prints a given react component
// react component for creating dynamic tables
// react component used to create sweet alerts
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
// work order component
import Select from "react-select";
import * as adminEndpoints from "../../../endpoints/admin.endpoint";
import { adminRoutes } from "../../../endpoints/base.endpoint";

function VendorManagerAssignStation() {
  let newAssignStationValue = {};
  const [NewAssignStationObject, setNewAssignStationObject] = useState();
  const [assignedManager, setAssignedManager] = useState([]);

  useEffect(() => {
    console.log(NewAssignStationObject);
  }, [NewAssignStationObject]);

  const handleNewWO = (typeofObj, value) => {
    newAssignStationValue[typeofObj] = value;
    setNewAssignStationObject((NewAssignStationObject) => ({
      ...NewAssignStationObject,
      ...newAssignStationValue,
    }));
    console.log(NewAssignStationObject);
  };

  let Routes;
  let endpoints;

  Routes = adminRoutes;
  endpoints = adminEndpoints;

  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  const [
    { data: getStation, loading: getStationLoading, error: getStationError },
    refreshgetStation,
  ] = Routes(
    {
      url: endpoints.list_station.path,
      method: endpoints.list_station.method,
      headers: config.header,
    },
    { manual: false }
  );

  // transform data of stations to display in select input field
  let StationList = [];
  if (getStation) {
    getStation.data.map((data, idx) =>
      StationList.push({ value: data, label: data.stationName })
    );
  }

  // VENDOR MANAGER API
  const [
    { data: getManager, loading: getManagerLoading, error: getManagerError },
    refreshManager,
  ] = Routes(
    {
      url: endpoints.list_managers.path,
      method: endpoints.list_managers.method,
      headers: config.header,
    },
    { manual: false }
  );

  console.log(getManager);

  // transform data of stations to display in select input field
  let ManagerList = [];
  if (getManager) {
    getManager.map((data, idx) =>
      ManagerList.push({ value: data, label: data.name })
    );
  }

  useEffect(() => {
    if (NewAssignStationObject) {
      const selectedStation = StationList.find(
        (station) => station.value._id === NewAssignStationObject.sid
      );
      const foundManager = [];
      if (
        selectedStation &&
        selectedStation.value &&
        selectedStation.value.vendorManager
      ) {
        for (let i = 0; i < selectedStation.value?.vendorManager.length; i++) {
          for (let j = 0; j < ManagerList.length; j++) {
            if (
              selectedStation?.value?.vendorManager[i] ===
              ManagerList[j].value._id
            ) {
              foundManager.push(ManagerList[j].value);
              console.log("currentManager", ManagerList[j].value);
            }
          }
        }
        if (foundManager.length > 0) {
          setAssignedManager(foundManager);
        } else {
          setAssignedManager([]);
        }
      } else {
        setAssignedManager([]);
      }
    }
  }, [NewAssignStationObject]);

  // ASSIGN VENDOR MANAGER TO STATION API
  const [
    { loading: assignManagerLoading, error: assignManagerError },
    assignVendorManager,
  ] = Routes(
    {
      url: endpoints.assign_vendor_manager.path,
      method: endpoints.assign_vendor_manager.method,
      headers: config.header,
      data: NewAssignStationObject,
    },
    { manual: true }
  );

  function handleNewAssignStationSubmit() {
    assignVendorManager().then((res) => {
      window.location.href = "/admin/manager-list";
      console.log("redirected");
      console.log("assign manager", res);
    });
  }
  console.log("assignedManager", assignedManager);
  return (
    <>
      <SimpleHeader
        name="Assign Vendor Manager To Station"
        parentName="Vendor"
      />
      <Container className="mt--6" fluid>
        <Card>
          {/* <CardHeader className="bg-transparent">
            <h3 className="mb-0 ">Assign Vendor Manager To Station</h3>
          </CardHeader> */}
          <CardBody>
            <Form>
              <h6 className="heading-small text-muted mb-4">
                Station And Vendor Manager information
              </h6>
              <div className="px-lg-4">
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="u_id">
                        Station To Assign
                      </label>
                      <Select
                        options={StationList}
                        id={"station"}
                        onChange={(e) => {
                          handleNewWO("sid", e.value._id);
                        }}
                      />
                    </FormGroup>
                    {assignedManager.length > 0 && (
                      <table className="w-100">
                        <thead>
                          <tr>
                            <th colSpan={3} className="text-center border py-2">
                              Assgined Manager
                            </th>
                          </tr>
                          <tr>
                            <th className="border text-center px-5">
                              Manager Name
                            </th>
                            <th className="border text-center px-5">
                              Manager Email
                            </th>
                            <th className="border text-center px-5">
                              Manager Phone
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {assignedManager.map((data, idx) => (
                            <tr key={idx}>
                              <td className="border text-center px-5">
                                {data.name}
                              </td>
                              <td className="border text-center px-5">
                                {data.email}
                              </td>
                              <td className="border text-center px-5">
                                {data.phone}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="u_id">
                        Vendor Manager To Assign
                      </label>
                      <Select
                        options={ManagerList}
                        id={"Manager"}
                        onChange={(e) => {
                          handleNewWO("mid", e.value._id);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </Form>
          </CardBody>
          <CardFooter>
            <Button color="default" onClick={handleNewAssignStationSubmit}>
              Assign Vendor Manager
            </Button>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
}

export default VendorManagerAssignStation;
