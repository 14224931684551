import React, { useEffect, useState } from "react";
// react plugin that prints a given react component
// react component for creating dynamic tables
// react component used to create sweet alerts
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  Row,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
// work order component
import { adminRoutes } from "../../../endpoints/base.endpoint";
import * as adminEndpoints from "../../../endpoints/admin.endpoint";

function ClientOwnerEdit() {
  const owner_id = sessionStorage.getItem("client_user_edit_id");
  const [isActiveModal, setisActiveModal] = useState(false);
  const [accountStatus, setAccountStatus] = useState();

  const [NewOwnerObject, setNewOwnerObject] = useState({
    id: "",
    name: "",
    email: "",
    phone: "",
    companyName: "",
    companyAddress: "",
    companyCity: "",
    companyState: "",
    companyZip: "",
    companyCountry: "",
    isActive: false,
  });
  let newOwnerValue = {};
  console.log("old", NewOwnerObject);
  console.log("new", newOwnerValue);
  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  let Routes;
  let endpoints;

  Routes = adminRoutes;
  endpoints = adminEndpoints;

  // GET SELECTED OWNER DETAIL
  const [
    { data: getOwner, loading: getOwnerLoading, error: getOwnerError },
    refreshOwner,
  ] = Routes(
    {
      url: endpoints.view_owner.path,
      method: endpoints.view_owner.method,
      headers: config.header,
      data: {
        id: owner_id,
      },
    },
    { manual: false }
  );

  // SET SELECTED OWNER DETAIL AS DEFAULT VALUE
  useEffect(() => {
    if (getOwner) {
      setNewOwnerObject({
        id: getOwner._id,
        name: getOwner.name,
        email: getOwner.email,
        phone: getOwner.phone,
        companyName: getOwner.companyName || "",
        companyAddress: getOwner.companyAddress || "",
        companyCity: getOwner.companyCity || "",
        companyState: getOwner.companyState || "",
        companyZip: getOwner.companyZip || "",
        companyCountry: getOwner.companyCountry || "",
        setAccountStatus: getOwner.isActive,
      });
    }
  }, [getOwner]);
  console.log("owner", getOwner);
  useEffect(() => {
    console.log(NewOwnerObject);
  }, [NewOwnerObject]);

  const handleEditCO = (typeofObj, value) => {
    newOwnerValue[typeofObj] = value;
    setNewOwnerObject((NewOwnerObject) => ({
      ...NewOwnerObject,
      ...newOwnerValue,
    }));
    console.log(NewOwnerObject);
  };

  // UPDATE OWNER DETAIL API
  const [
    { loading: updateOwnerLoading, error: updateOwnerError },
    updateOwner,
  ] = adminRoutes(
    {
      url: adminEndpoints.edit_owner.path,
      method: adminEndpoints.edit_owner.method,
      headers: config.header,
      data: NewOwnerObject,
    },
    { manual: true }
  );

  // ACCOUNT STATUS UPDATE API
  const [{ loading, error }, changeStatus] = adminRoutes(
    {
      url: adminEndpoints.change_client_user_status.path,
      method: adminEndpoints.change_client_user_status.method,
      headers: config.header,
      data: {
        id: owner_id,
        status: accountStatus,
      },
    },
    { manual: true }
  );

  function handleEditClientUserSubmit() {
    updateOwner().then((res) => {
      console.log(res);
      console.log("redirect");
      window.location.href = "/admin/client-user-list";
    });
  }

  //TOOGLE MODAL
  function closeModal() {
    if (isActiveModal) {
      setisActiveModal(false);
    } else {
      setisActiveModal(true);
    }
  }

  // HANDLE ACCOUNT ACITVE SUBMIT
  function handleStatusSubmit() {
    console.log("account status", accountStatus);
    closeModal();
    changeStatus()
      .then((res) => {
        console.log("status", res);
        refreshOwner();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <SimpleHeader name="Client Owner Edit" parentName="Client" />
      <Modal
        className="modal-dialog-centered w-100 h-100"
        backdrop={true}
        size="md"
        centered={true}
        scrollable={true}
        isOpen={isActiveModal}
        toggle={closeModal}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Active/Deactive Account
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <div>
              <Row>
                <Col lg="4">
                  <FormGroup>
                    <div
                      className="mb-2"
                      style={{ fontSize: "0.875rem", fontWeight: "600" }}
                    >
                      Is Active?
                    </div>
                    <label className="custom-toggle">
                      <input
                        defaultChecked={getOwner && getOwner.isActive}
                        type="checkbox"
                        onChange={(e) => setAccountStatus(e.target.checked)}
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="modal-footer">
          <Button color="danger" onClick={handleStatusSubmit}>
            Submit
          </Button>{" "}
          <Button onClick={closeModal}>Cancel</Button>
        </div>
      </Modal>
      <Container className="mt--6" fluid>
        {getOwner && (
          <Card>
            {/* <CardHeader className="bg-transparent d-flex align-items-center justify-content-between">
              <h3 className="mb-0 ">Client Owner Edit</h3>
              <button className="btn btn-warning btn-sm" onClick={closeModal}>
                Status
              </button>
            </CardHeader> */}
            <CardBody>
              <Form>
                <div className="bg-transparent d-flex align-items-center justify-content-between">
                  <a href="./client-user-list" className="text-white mb-3">
                    <Button size="sm" color="default" type="button">
                      Back
                    </Button>
                  </a>
                  <button
                    className="btn btn-warning btn-sm"
                    onClick={closeModal}
                  >
                    Status
                  </button>
                </div>
                <h6 className="heading-small text-muted mb-4">
                  Client Owner Basic Information
                </h6>
                <div className="px-lg-4">
                  <Row>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-user-name"
                        >
                          Name
                        </label>
                        <Input
                          id="input-user-name"
                          rows="6"
                          type={"text"}
                          placeholder={"Name"}
                          defaultValue={getOwner.name}
                          onChange={(e) => {
                            handleEditCO("name", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-user-email"
                        >
                          Email
                        </label>
                        <Input
                          id="input-user-email"
                          type={"text"}
                          placeholder={"Owner Email"}
                          defaultValue={getOwner.email}
                          onChange={(e) => {
                            handleEditCO("email", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-station-phone"
                        >
                          Phone
                        </label>
                        <Input
                          id="input-station-phone"
                          rows="6"
                          type={"text"}
                          placeholder={"Phone"}
                          defaultValue={getOwner.phone}
                          onChange={(e) => {
                            handleEditCO("phone", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <hr className="my-4" />

                <h6 className="heading-small text-muted mb-4">
                  Owner Company Information
                </h6>
                <div className="px-lg-4">
                  <Row>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-company-name"
                        >
                          Company Name
                        </label>
                        <Input
                          id="input-company-name"
                          rows="6"
                          type={"text"}
                          placeholder={"Company Name"}
                          defaultValue={getOwner.companyName}
                          onChange={(e) => {
                            handleEditCO("companyName", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-company-address"
                        >
                          Company Address
                        </label>
                        <Input
                          id="input-company-address"
                          rows="6"
                          type={"text"}
                          placeholder={"Company Address"}
                          defaultValue={getOwner.companyAddress}
                          onChange={(e) => {
                            handleEditCO("companyAddress", e.target.value);
                          }}
                          maxLength={"50"}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="company-city"
                        >
                          Company City
                        </label>
                        <Input
                          id="company-city"
                          rows="6"
                          type={"text"}
                          placeholder={"Company City"}
                          defaultValue={getOwner.companyCity}
                          onChange={(e) => {
                            handleEditCO("companyCity", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="company-state"
                        >
                          Company State
                        </label>
                        <Input
                          id="company-state"
                          rows="6"
                          type={"text"}
                          placeholder={"Company State"}
                          defaultValue={getOwner.companyState}
                          onChange={(e) => {
                            handleEditCO("companyState", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="company-country"
                        >
                          Company Country
                        </label>
                        <Input
                          id="company-country"
                          rows="6"
                          type={"text"}
                          placeholder={"Company Country"}
                          defaultValue={getOwner.companyCountry}
                          onChange={(e) => {
                            handleEditCO("companyCountry", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="company-zip"
                        >
                          Company Zip
                        </label>
                        <Input
                          id="company-zip"
                          rows="6"
                          type={"text"}
                          placeholder={"Company Zip"}
                          defaultValue={getOwner.companyZip}
                          onChange={(e) => {
                            handleEditCO("companyZip", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </Form>
            </CardBody>
            <CardFooter>
              <Button color="default" onClick={handleEditClientUserSubmit}>
                Update Owner
              </Button>
              <a className="btn btn-danger" href="./client-user-list">
                Cancel
              </a>
            </CardFooter>
          </Card>
        )}
      </Container>
    </>
  );
}

export default ClientOwnerEdit;
