import CustomProfileHeader from "components/Headers/CustomProfileHeader";
import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { adminRoutes } from "../../../endpoints/base.endpoint";
import * as adminEndpoints from "../../../endpoints/admin.endpoint";

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 100,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            defaultValue={"100"}
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

function ClientOwnerDetails() {
  const componentRef = React.useRef(null);
  const owner_id = sessionStorage.getItem("client_user_manage_id");
  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  let Routes;
  let endpoints;
  Routes = adminRoutes;
  endpoints = adminEndpoints;
  const [
    {
      data: getClientOwner,
      loading: getClientOwnerLoading,
      error: getClientOwnerError,
    },
  ] = Routes({
    url: endpoints.view_owner.path,
    method: endpoints.view_owner.method,
    headers: config.header,
    data: {
      id: owner_id,
    },
  });
  console.log("get client owner", getClientOwner);

  const managerList = [
    {
      name: "Aura Manager",
      phone: "1212232321",
      email: "manager@auranics.com",
      stations: "1",
    },
    {
      name: "Manager Aura",
      phone: "7845787878",
      email: "mgr@auranics.com",
      stations: "1",
    },
    {
      name: "New Manager",
      phone: "789654135",
      email: "newmg@auranics.com",
      stations: "1",
    },
    {
      name: "AS Manager",
      phone: "4815784525",
      email: "asm@auranics.com",
      stations: "1",
    },
    {
      name: "Manager AS",
      phone: "9874858574",
      email: "mas@auranics.com",
      stations: "1",
    },
  ];

  managerList.map(
    (data) =>
      (data.btn = (
        <>
          <a
            href={"./client-manager-details"}
            className="btn btn-success  btn-sm  d-print-none"
            onClick={(e) =>
              sessionStorage.setItem("client_manager_manage_id", data._id)
            }
          >
            Details
          </a>
        </>
      ))
  );

  const employeeList = [
    {
      name: "Aura Employee",
      phone: "1212232321",
      email: "employee@auranics.com",
      assignedStation: "Fuel Max 1",
    },
    {
      name: "Employee Aura",
      phone: "7845787878",
      email: "emp@auranics.com",
      assignedStation: "Fuel Max 2",
    },
    {
      name: "New Employee",
      phone: "789654135",
      email: "newemp@auranics.com",
      assignedStation: "Fuel Max 3",
    },
    {
      name: "AS Employee",
      phone: "4815784525",
      email: "ase@auranics.com",
      assignedStation: "Fuel Max 4",
    },
    {
      name: "Employee AS",
      phone: "9874858574",
      email: "eas@auranics.com",
      assignedStation: "Fuel Max 5",
    },
  ];

  return (
    <>
      <CustomProfileHeader heading={"Client Owner Details"} />
      {getClientOwner && (
        <Container className="mt--6" fluid>
          <Row>
            <Col className="order-lg-1" lg="12">
              <Card className="card-profile">
                <CardHeader className="text-center border-0 pt-4 pt-md-4 pb-0 pb-md-4">
                  <a
                    href="./client-user-list"
                    className="text-white float-left"
                  >
                    <Button size="sm" color="default" type="button">
                      Back
                    </Button>
                  </a>
                  <div className="row">
                    <div className="col-sm-10 col-md-10 col-lg-11">
                      <div className="d-flex justify-content-center">
                        <span className={"display-1"}>
                          {getClientOwner.name}
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-02 col-md-2 col-lg-1 align-self-center">
                      <a
                        href={"/admin/client-user-edit"}
                        className="btn btn-warning d-print-none"
                        onClick={(e) => {
                          sessionStorage.setItem(
                            "client_user_edit_id",
                            owner_id
                          );
                        }}
                      >
                        Edit
                      </a>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className="col-12">
                      <div className="card-profile-stats d-flex justify-content-center"></div>
                    </div>
                  </Row>
                  <Row className="p-4">
                    <Col lg="6" className={"h3 mt-2"}>
                      <span className={"h4 font-weight-light p-1"}>
                        Email : <a href={"mailto:"}>{getClientOwner.email}</a>
                      </span>
                    </Col>
                    <Col lg="6" className={"h3 mt-2"}>
                      <span className={"h4 font-weight-light p-1"}>
                        Phone : <a href={"tel:"}>{getClientOwner.phone}</a>
                      </span>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* STATION LIST */}
          {getClientOwner.station_list.length > 0 && (
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Assgined Station List</h3>
                  </Col>
                  <Col className="text-right" xs="4"></Col>
                </Row>
              </CardHeader>
              <Row>
                <div className="col">
                  <ToolkitProvider
                    data={getClientOwner.station_list}
                    keyField="_id"
                    columns={[
                      {
                        dataField: "stationName",
                        text: "Name",
                        sort: true,
                      },
                      {
                        dataField: "stationAddress",
                        text: "Address",
                        sort: true,
                      },
                      {
                        dataField: "phone",
                        text: "Phone",
                        sort: false,
                      },
                      {
                        dataField: "email",
                        text: "Email",
                        sort: false,
                      },
                    ]}
                    search
                  >
                    {(props) => (
                      <div className="py-4 px-4 table-responsive">
                        <Container fluid>
                          <Row>
                            <Col xs={12} sm={6}>
                              <div
                                id="datatable-basic_filter"
                                className="dataTables_filter pb-1"
                              >
                                <label>
                                  Search:
                                  <SearchBar
                                    className="form-control-sm"
                                    placeholder=""
                                    {...props.searchProps}
                                  />
                                </label>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                        <BootstrapTable
                          ref={componentRef}
                          {...props.baseProps}
                          bootstrap4={true}
                          pagination={pagination}
                          bordered={true}
                          striped
                          hover
                          condensed
                          id="react-bs-table"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                </div>
              </Row>
            </Card>
          )}
          {/* MANAGER LIST */}
          {/* <Card>
            <CardHeader>
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Assgined Manager List</h3>
                </Col>
                <Col className="text-right" xs="4"></Col>
              </Row>
            </CardHeader>
            <Row>
              <div className="col">
                <ToolkitProvider
                  data={managerList}
                  keyField="name"
                  columns={[
                    {
                      dataField: "name",
                      text: "Name",
                      sort: true,
                    },
                    {
                      dataField: "phone",
                      text: "Phone",
                      sort: false,
                    },
                    {
                      dataField: "email",
                      text: "Email",
                      sort: false,
                    },
                    {
                      dataField: "stations",
                      text: "Assigned Stations",
                      sort: false,
                    },
                    {
                      dataField: "btn",
                      text: "Action",
                      sort: false,
                    },
                  ]}
                  search
                >
                  {(props) => (
                    <div className="py-4 px-4 table-responsive">
                      <Container fluid>
                        <Row>
                          <Col xs={12} sm={6}>
                            <div
                              id="datatable-basic_filter"
                              className="dataTables_filter pb-1"
                            >
                              <label>
                                Search:
                                <SearchBar
                                  className="form-control-sm"
                                  placeholder=""
                                  {...props.searchProps}
                                />
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                      <BootstrapTable
                        ref={componentRef}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={true}
                        striped
                        hover
                        condensed
                        id="react-bs-table"
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </Row>
          </Card> */}
          {/* EMPLOYEE LIST */}
          {/* <Card>
            <CardHeader>
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Assigned Employee List</h3>
                </Col>
                <Col className="text-right" xs="4"></Col>
              </Row>
            </CardHeader>
            <Row>
              <div className="col">
                <ToolkitProvider
                  data={employeeList}
                  keyField="name"
                  columns={[
                    {
                      dataField: "name",
                      text: "Name",
                      sort: true,
                    },
                    {
                      dataField: "phone",
                      text: "Phone",
                      sort: false,
                    },
                    {
                      dataField: "email",
                      text: "Email",
                      sort: false,
                    },
                    {
                      dataField: "assignedStation",
                      text: "Assigned Stations",
                      sort: false,
                    },
                  ]}
                  search
                >
                  {(props) => (
                    <div className="py-4 px-4 table-responsive">
                      <Container fluid>
                        <Row>
                          <Col xs={12} sm={6}>
                            <div
                              id="datatable-basic_filter"
                              className="dataTables_filter pb-1"
                            >
                              <label>
                                Search:
                                <SearchBar
                                  className="form-control-sm"
                                  placeholder=""
                                  {...props.searchProps}
                                />
                              </label>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                      <BootstrapTable
                        ref={componentRef}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={true}
                        striped
                        hover
                        condensed
                        id="react-bs-table"
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </div>
            </Row>
          </Card> */}
        </Container>
      )}
    </>
  );
}

export default ClientOwnerDetails;
