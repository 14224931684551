import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
// react plugin that prints a given react component
// react component for creating dynamic tables
// react component used to create sweet alerts
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import Select from "react-select";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
// work order component
import { adminRoutes } from "../../../endpoints/base.endpoint";
import * as adminEndpoints from "../../../endpoints/admin.endpoint";
import CmdAndCmdTimeInput from "components/Station/CmdAndCmdTimeInput";

function GatewayDetail() {
  // GET STATION ID FROM SESSION STORAGE
  const station_id = sessionStorage.getItem("station_subscription_manage_id");
  const [loops, setLoops] = useState([]);
  const [intervals, setIntervals] = useState([]);
  const [cmd, setCmd] = useState([""]);
  const [cmdTime, setCmdTime] = useState([""]);
  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  const loopOptions = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "3", label: "3" },
    { value: "4", label: "4" },
    { value: "5", label: "5" },
  ];

  const intervalOptions = [
    { value: "1", label: "1" },
    { value: "2", label: "2" },
    { value: "4", label: "4" },
    { value: "8", label: "8" },
    { value: "10", label: "10" },
    { value: "15", label: "15" },
  ];

  const handleInputChange = (e, index) => {
    const updatedFields = [...cmdTime];
    updatedFields[index] = e.target.value;
    setCmdTime(updatedFields);
  };

  const handleSelectChange = (e, index) => {
    const updatedFields = [...cmd];
    updatedFields[index] = e.target.value;
    setCmd(updatedFields);
  };

  const handleAddField = () => {
    setCmdTime([...cmdTime, ""]);
    setCmd([...cmd, ""]);
  };

  const handleSubmit = () => {
    console.log("LOOP", loops);
    console.log("INTERVAL", intervals);
    console.log("CMD List", cmd);
    console.log("CMD TIME", cmdTime);
  };
  return (
    <>
      <SimpleHeader name="Gateway Detail" parentName="Station" />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader className="bg-transparent">
            <h3 className="mb-0 ">Gateway Detail</h3>
          </CardHeader>
          <CardBody>
            <Form>
              <h6 className="heading-small text-muted mb-4">
                Station Gateway Detail
              </h6>
              <div className="px-lg-4">
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="loop">
                        Loop
                      </label>
                      <Select
                        id="loop"
                        options={loopOptions}
                        isMulti
                        onChange={(e) => setLoops(e.map((d) => d.value))}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="interval">
                        Interval
                      </label>
                      <Select
                        id="interval"
                        options={intervalOptions}
                        isMulti
                        onChange={(e) => setIntervals(e.map((d) => d.value))}
                      />
                    </FormGroup>
                  </Col>
                  {cmdTime.map((value, index) => (
                    <div key={index} className="w-100 d-flex">
                      <Col lg="6">
                        <FormGroup>
                          <label htmlFor="cmd" className="form-control-label">
                            Command
                          </label>
                          <Input
                            type="select"
                            value={cmd[index]}
                            onChange={(e) => handleSelectChange(e, index)}
                          >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="cmdTime"
                          >
                            Command Time
                          </label>

                          <Input
                            type="time"
                            value={value}
                            id="cmdTime"
                            onChange={(e) => handleInputChange(e, index)}
                          />
                          {index === cmdTime.length - 1 && (
                            <button
                              className="btn btn-default"
                              onClick={handleAddField}
                            >
                              Add
                            </button>
                          )}
                        </FormGroup>
                      </Col>
                    </div>
                  ))}
                </Row>
              </div>
            </Form>
          </CardBody>
          <CardFooter>
            <Button color="default" onClick={handleSubmit}>
              Subscribe
            </Button>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
}

export default GatewayDetail;
