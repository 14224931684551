import CustomProfileHeader from "components/Headers/CustomProfileHeader";
import React from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import { adminRoutes } from "../../../endpoints/base.endpoint";
import * as adminEndpoints from "../../../endpoints/admin.endpoint";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 100,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            defaultValue={"100"}
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

function JobberDetails() {
  const componentRef = React.useRef(null);

  const jobber_id = sessionStorage.getItem("jobber_manage_id");

  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  let Routes;
  let endpoints;

  Routes = adminRoutes;
  endpoints = adminEndpoints;

  const [
    { data: getJobber, loading: getJobberLoading, error: getJobberError },
  ] = Routes({
    url: endpoints.view_jobber.path,
    method: endpoints.view_jobber.method,
    headers: config.header,
    data: {
      jid: jobber_id,
    },
  });

  console.log(getJobber);

  const stationList = [
    {
      stationName: "station",
      stationAddress: "123",
      phone: "123456789",
      email: "station@gmail.com",
    },
    {
      stationName: "station1",
      stationAddress: "123",
      phone: "123456789",
      email: "station1@gmail.com",
    },
    {
      stationName: "station2",
      stationAddress: "123",
      phone: "123456789",
      email: "station2@gmail.com",
    },
    {
      stationName: "station3",
      stationAddress: "123",
      phone: "123456789",
      email: "station3@gmail.com",
    },
    {
      stationName: "station4",
      stationAddress: "123",
      phone: "123456789",
      email: "station4@gmail.com",
    },
  ];

  return (
    <>
      <CustomProfileHeader heading={"Jobber Details"} />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-lg-1" lg="12">
            <Card className="card-profile">
              <CardHeader className="text-center border-0 pt-4 pt-md-4 pb-0 pb-md-4">
                <div className="row align-items-center">
                  <div className="col-sm-2 col-md-2 col-lg-1">
                    <a href="./jobber-list" className="text-white float-left">
                      <Button size="sm" color="default" type="button">
                        Back
                      </Button>
                    </a>
                  </div>
                  <div className="col-sm-8 col-md-8 col-lg-10">
                    <div className={"display-1 capitalize"}>
                      {getJobber && getJobber.name}
                    </div>
                  </div>
                  <div className="col-sm-2 col-md-2 col-lg-1 align-self-center">
                    <a
                      href={"./jobber-edit"}
                      className="btn btn-warning d-print-none"
                      onClick={(e) =>
                        sessionStorage.setItem("jobber_edit_id", jobber_id)
                      }
                    >
                      Edit
                    </a>
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Row>
                  <div className="col-12">
                    <div className="card-profile-stats d-flex justify-content-center"></div>
                  </div>
                </Row>
                <Row className="p-4">
                  <Col lg="6" className={"h3 mt-2"}>
                    <span className={"h4 font-weight-light p-1"}>
                      Jobber Email :{" "}
                      <a href={"mailto:"}>{getJobber && getJobber.email}</a>
                    </span>
                  </Col>
                  <Col lg="6" className={"h3 mt-2"}>
                    <span className={"h4 font-weight-light p-1"}>
                      Jobber Phone :{" "}
                      <a href={"tel:"}>{getJobber && getJobber.phone}</a>
                    </span>
                  </Col>
                  <Col lg="6" className={"h3 mt-2"}>
                    <span className={"h4 font-weight-light p-1"}>
                      company Name : {getJobber && getJobber.companyName}
                    </span>
                  </Col>
                  <Col lg="6" className={"h3 mt-2"}>
                    <span className={"h4 font-weight-light p-1"}>
                      Company Address: {getJobber && getJobber.companyAddress}
                    </span>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* STATION LIST */}
        <Card>
          <CardHeader>
            <Row className="align-items-center">
              <Col xs="8">
                <h3 className="mb-0">Assgined Station List</h3>
              </Col>
              <Col className="text-right" xs="4"></Col>
            </Row>
          </CardHeader>
          <Row>
            <div className="col">
              <ToolkitProvider
                data={stationList}
                keyField="name"
                columns={[
                  {
                    dataField: "stationName",
                    text: "Name",
                    sort: true,
                  },
                  {
                    dataField: "stationAddress",
                    text: "Address",
                    sort: true,
                  },
                  {
                    dataField: "phone",
                    text: "Phone",
                    sort: false,
                  },
                  {
                    dataField: "email",
                    text: "Email",
                    sort: false,
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 px-4 table-responsive">
                    <Container fluid>
                      <Row>
                        <Col xs={12} sm={6}>
                          <div
                            id="datatable-basic_filter"
                            className="dataTables_filter pb-1"
                          >
                            <label>
                              Search:
                              <SearchBar
                                className="form-control-sm"
                                placeholder=""
                                {...props.searchProps}
                              />
                            </label>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                    <BootstrapTable
                      ref={componentRef}
                      {...props.baseProps}
                      bootstrap4={true}
                      pagination={pagination}
                      bordered={true}
                      striped
                      hover
                      condensed
                      id="react-bs-table"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </Row>
        </Card>
      </Container>
    </>
  );
}

export default JobberDetails;
