import React, { useEffect, useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import SimpleHeader from "components/Headers/SimpleHeader";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Collapse,
  Container,
  Row,
  Modal,
  Form,
  FormGroup,
} from "reactstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTimes,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { adminRoutes } from "../../../endpoints/base.endpoint";
import * as adminEndpoints from "../../../endpoints/admin.endpoint";

function StationEdit() {
  const [allStations, setAllStations] = useState([]);
  const [isDeviceGatewayChecked, setIsDeviceGatewayChecked] = useState(false);
  // Use an array of booleans to manage the collapse state for each card
  const [cardCollapseStates, setCardCollapseStates] = useState([]);
  const _id = sessionStorage.getItem("station_edit_id");
  const [isActiveModal, setisActiveModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [stationStatus, setStationStatus] = useState();

  const toggle = (index) => {
    // Toggle the collapse state for the clicked card
    setCardCollapseStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  // GET ALL STATION API
  const [{ data, loading, error }] = adminRoutes({
    url: adminEndpoints.list_every_station.path,
    method: adminEndpoints.list_every_station.method,
    headers: config.header,
  });

  useEffect(() => {
    if (data) {
      setAllStations(data);
    }
  }, [data]);

  // GET SELECTED STATION'S DETAILS
  const [
    { data: getStation, loading: getStationLoading, error: getStationError },
    refreshStation,
  ] = adminRoutes({
    url: adminEndpoints.view_station.path,
    method: adminEndpoints.view_station.method,
    headers: config.header,
    data: { id: _id },
  });

  const [
    { data: updateStation, loading: updateSLoading, error: updateSError },
    updateStationRequest,
  ] = adminRoutes(
    {
      url: adminEndpoints.edit_station.path,
      method: adminEndpoints.edit_station.method,
      headers: config.header,
    },
    { manual: true }
  );

  // ACCOUNT STATUS UPDATE API
  const [{ loading: statusLoading, error: statusError }, changeStatus] =
    adminRoutes(
      {
        url: adminEndpoints.change_station_status.path,
        method: adminEndpoints.change_station_status.method,
        headers: config.header,
        data: {
          sid: _id,
          status: stationStatus,
        },
      },
      { manual: true }
    );

  const {
    control,
    setValue,
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    if (getStation) {
      setValue("stationName", getStation.stationName || "");
      setValue("stationAddress", getStation.stationAddress || "");
      setValue("city", getStation.city || "");
      setValue("state", getStation.state || "");
      setValue("zip", getStation.zip || "");
      setValue("email", getStation.email || "");
      setValue("phone", getStation.phone || "");
      setValue("stationContact", getStation.stationContact || "");
      setValue("ATGModel", getStation.ATGModel || "");
      setValue("gateway", getStation.gateway || false);
      setValue("deviceID", getStation.deviceID || "");
      setValue("facilityId", getStation.facilityId || "");
      setValue("ownerId", getStation.ownerId || "");
      setValue("ATGtype", getStation.ATGtype || "");
      setValue("consoleType", getStation.consoleType || "");
      setValue("dispenserType", getStation.dispenserType || "");
      setValue2("gateway", getStation.gateway || false);
      if (getStation.tank) {
        setValue(
          "tank",
          Object.values(getStation.tank) || {
            capacity: "",
            type: "",
            color: "",
            minRatio: 20,
          }
        );
      }
      if (getStation.shift) {
        setValue("shift", Object.values(getStation.shift));
      } else {
        setValue("shift", [
          {
            status: false,
            time: "00:00",
          },
          {
            status: false,
            time: "00:00",
          },
          {
            status: false,
            time: "00:00",
          },
          {
            status: false,
            time: "00:00",
          },
        ]);
      }
    }
  }, [getStation]);

  // react-hook-form fieldArray to add/remove dynamic tank as need
  const { fields, append, remove } = useFieldArray({
    name: "tank",
    control,
  });

  const { fields: shiftFields } = useFieldArray({
    name: "shift",
    control,
  });

  // this function remove empty key from object
  function removeEmptyFields(data) {
    Object.keys(data).forEach((key) => {
      if (data[key] === "" || data[key] == null) {
        delete data[key];
      }
    });
  }

  const onSubmit = async (data, action) => {
    console.log(data);
    // function to remove not required empty input fields
    removeEmptyFields(data);

    if (!getStation.deviceID) {
      // check if entered deviceID already exists
      let duplicateDeviceId;
      if (data.deviceID) {
        duplicateDeviceId = allStations.some(
          (station) => station.deviceID === data.deviceID
        );
      }

      if (duplicateDeviceId) {
        setError("deviceID", {
          type: "manual",
          message: "Device ID already exists",
        });
        return;
      }
    }

    // this transformed array of tank in tank object
    const transformedTank = data.tank.reduce((acc, tank, index) => {
      acc[(index + 1).toString().padStart(2, "0")] = tank;
      return acc;
    }, {});

    const newData = { ...data };
    newData.tank = transformedTank;
    newData.sid = _id;

    // this transformed array of shift in shift object
    const transformedShift = data.shift.reduce((acc, shift, index) => {
      acc[(index + 1).toString().padStart(2, "0")] = shift;
      return acc;
    }, {});

    newData.shift = transformedShift;

    const editStationResponse = await updateStationRequest({
      data: newData,
    });

    if (editStationResponse) {
      sessionStorage.removeItem("station_edit_id");
      sessionStorage.setItem("station_manage_id", _id);
      window.location.href = "/admin/station-details";
    }
  };

  // tank and tank colors select options
  const regular = [
    {
      label: "Regular (Charlotte)",
      value: {
        fuelType: "Regular",
        color: { label: "Charlotte", value: "#B9FBF3" },
      },
    },
    {
      label: "Regular (Turquoise Blue)",
      value: {
        fuelType: "Regular",
        color: { label: "Turquoise Blue", value: "#51F4E1" },
      },
    },
    {
      label: "Regular (Keppel)",
      value: {
        fuelType: "Regular",
        color: { label: "Keppel", value: "#39AB9E" },
      },
    },
  ];

  const sup = [
    {
      label: "Super (Rose Bud)",
      value: {
        fuelType: "Super",
        color: { label: "Rose Bud", value: "#F8B1A2" },
      },
    },
    {
      label: "Super (Flamingo)",
      value: {
        fuelType: "Super",
        color: { label: "Flamingo", value: "#F06244" },
      },
    },
    {
      label: "Super (Mojo)",
      value: {
        fuelType: "Super",
        color: { label: "Mojo", value: "#C04E36" },
      },
    },
  ];

  const diesel = [
    {
      label: "Diesel (Vista Blue)",
      value: {
        fuelType: "Diesel",
        color: { label: "Vista Blue", value: "#9BDAC2" },
      },
    },
    {
      label: "Diesel (Keppel)",
      value: {
        fuelType: "Diesel",
        color: { label: "Keppel", value: "#36B485" },
      },
    },
    {
      label: "Diesel (Eucalyptus)",
      value: {
        fuelType: "Diesel",
        color: { label: "Eucalyptus", value: "#267E5D" },
      },
    },
  ];

  const truckDSL = [
    {
      label: "Truck DSL (Banana Mania)",
      value: {
        fuelType: "Truck DSL",
        color: { label: "Banana Mania", value: "#FCE5B5" },
      },
    },
    {
      label: "Truck DSL (Casablanca)",
      value: {
        fuelType: "Truck DSL",
        color: { label: "Casablanca", value: "#F7BF47" },
      },
    },
    {
      label: "Truck DSL (Luxor Gold)",
      value: {
        fuelType: "Truck DSL",
        color: { label: "Luxor Gold", value: "#AD8632" },
      },
    },
  ];

  const DEF = [
    {
      label: "DEF (Perano)",
      value: {
        fuelType: "DEF",
        color: { label: "Perano", value: "#A8CAF1" },
      },
    },
    {
      label: "DEF (Mariner)",
      value: {
        fuelType: "DEF",
        color: { label: "Mariner", value: "#267ADD" },
      },
    },
    {
      label: "DEF (Fun Blue)",
      value: {
        fuelType: "DEF",
        color: { label: "Fun Blue", value: "#1B559D" },
      },
    },
  ];

  const tankProductsGroup = [
    { label: "Regular", options: regular },
    { label: "Super", options: sup },
    { label: "Diesel", options: diesel },
    { label: "Truck DSL", options: truckDSL },
    { label: "DEF", options: DEF },
  ];

  //   this function return index of tankProductsGroup and index of option
  function findColorIndexInTankProductsGroup(colorValue) {
    for (
      let groupIndex = 0;
      groupIndex < tankProductsGroup.length;
      groupIndex++
    ) {
      const optionIndex = tankProductsGroup[groupIndex].options.findIndex(
        (option) => option.value.color.value === colorValue
      );
      if (optionIndex !== -1) {
        return { groupIndex, optionIndex };
      }
    }

    // If the color is not found in any group
    return null;
  }

  // tank color select style
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.data.value.color.value,
      fontWeight: 500,
      // color: state.data.value,
      color: "black",
    }),
  };

  console.log(errors);

  //TOOGLE MODAL
  function closeModal() {
    if (isActiveModal) {
      setisActiveModal(false);
    } else {
      setisActiveModal(true);
    }
  }

  // HANDLE ACCOUNT ACITVE SUBMIT
  function handleStatusSubmit() {
    console.log("account status", stationStatus);
    closeModal();
    changeStatus()
      .then((res) => {
        console.log("status", res);
        refreshStation();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const [
    { loading: modifyDeviceLoading, error: modifyDeviceError },
    modifyDevice,
  ] = adminRoutes(
    {
      url: adminEndpoints.modify_device.path,
      method: adminEndpoints.modify_device.method,
      headers: config.header,
    },
    { manual: true }
  );

  const {
    control: control2,
    handleSubmit: handleSubmit2,
    register: register2,
    setValue: setValue2,
    formState: { errors: errors2 },
  } = useForm({
    defaultValues: {
      gatewayModified: false,
      sid: _id,
    },
  });

  console.log("errors", errors2);

  const handleDeviceGatewayChange = (e) => {
    setValue2(
      "gatewayModified",
      getStation && getStation.gateway !== e.target.checked
    );
    setIsDeviceGatewayChecked(e.target.checked);
  };

  const onSubmit2 = async (data) => {
    const modifyDeviceResponse = await modifyDevice({ data });
    console.log("modifyDeviceResponse", modifyDeviceResponse);
    closeDeviceGatewayModal();
    refreshStation();
  };

  // TOGGLE DEVICE GATEWAY EDIT MODAL
  function closeDeviceGatewayModal() {
    if (isEditModal) {
      setIsEditModal(false);
    } else {
      setIsEditModal(true);
    }
  }

  console.log("shiftField", shiftFields);

  return (
    <>
      <SimpleHeader name="Edit Station" parentName="Station" />
      <Modal
        className="modal-dialog-centered w-100 h-100"
        backdrop={true}
        size="md"
        centered={true}
        scrollable={true}
        isOpen={isActiveModal}
        toggle={closeModal}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Active/Deactive Station
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <div>
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <div
                      className="mb-2"
                      style={{ fontSize: "0.875rem", fontWeight: "600" }}
                    >
                      Is Active?
                    </div>
                    <label className="custom-toggle">
                      <input
                        defaultChecked={getStation && getStation.isActive}
                        type="checkbox"
                        onChange={(e) => setStationStatus(e.target.checked)}
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="modal-footer">
          <Button
            color="danger"
            onClick={handleStatusSubmit}
            disabled={statusLoading}
          >
            Submit
          </Button>{" "}
          <Button onClick={closeModal}>Cancel</Button>
        </div>
      </Modal>
      <Modal
        className="modal-dialog-centered w-100 h-100"
        backdrop={true}
        size="md"
        centered={true}
        scrollable={true}
        isOpen={isEditModal}
        toggle={closeDeviceGatewayModal}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Active/Deactive Station
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeDeviceGatewayModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <form onSubmit={handleSubmit2(onSubmit2)}>
            <div>
              <Row>
                <Col lg="6">
                  <Controller
                    name="gateway"
                    control={control2}
                    render={({ field: { value, onChange } }) => (
                      <div className="d-flex flex-column justify-content-end">
                        <div
                          className="mb-2 "
                          style={{
                            fontSize: "0.875rem",
                            fontWeight: "600",
                          }}
                        >
                          Device Gateway
                        </div>
                        <div className="">
                          <label className="custom-toggle">
                            <input
                              type="checkbox"
                              defaultChecked={value}
                              onChange={(e) => {
                                onChange(e);
                                handleDeviceGatewayChange(e);
                              }}
                            />
                            <span className="custom-toggle-slider rounded-circle " />
                          </label>
                        </div>
                      </div>
                    )}
                  />
                </Col>
                <Col lg="6">
                  <label htmlFor="deviceId" className="form-control-label">
                    Device Identifier
                  </label>
                  <input
                    type="text"
                    placeholder="Device Identifier"
                    {...register2("deviceID", {
                      maxLength: {
                        value: 12,
                        message:
                          "Device ID must be exactly 12 characters long.",
                      },
                      minLength: {
                        value: 12,
                        message:
                          "Device ID must be exactly 12 characters long.",
                      },
                      required: isDeviceGatewayChecked
                        ? "Device ID is required."
                        : false,
                      pattern: {
                        value: /^[A-Z0-9]{12}$/,
                        message: "Only uppercase letters and numbers.",
                      },
                    })}
                    className="form-control"
                  />
                  {errors2.deviceID && (
                    <span className="text-danger">
                      {errors2.deviceID?.message}
                    </span>
                  )}
                </Col>
              </Row>
            </div>
            <div className="modal-footer">
              <Button
                color="danger"
                type="submit"
                disabled={modifyDeviceLoading}
              >
                Submit
              </Button>{" "}
              <Button onClick={closeDeviceGatewayModal}>Cancel</Button>
            </div>
          </form>
        </div>
      </Modal>
      <Container className="mt--6" fluid>
        <Row>
          <Card>
            <CardHeader className="bg-transparent  d-flex align-items-center justify-content-between">
              <h3 className="mb-0">Edit Station</h3>
              <div>
                <button className="btn btn-warning btn-sm" onClick={closeModal}>
                  Status
                </button>
                <button
                  className="btn btn-warning btn-sm"
                  onClick={closeDeviceGatewayModal}
                >
                  Modify Device
                </button>
              </div>
            </CardHeader>
            <CardBody>
              <h6 className="heading-small text-muted mb-4">
                Station Basic Information
              </h6>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="4">
                      <label
                        htmlFor="stationName"
                        className="form-control-label"
                      >
                        Station Name
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Station Name"
                        {...register("stationName")}
                      />
                      {
                        <span className="text-danger">
                          {errors.stationName?.message}
                        </span>
                      }
                    </Col>
                    <Col lg="6">
                      <label
                        htmlFor="stationAddress"
                        className="form-control-label"
                      >
                        Station Address
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Station Address"
                        {...register("stationAddress")}
                      />
                      {
                        <span className="text-danger">
                          {errors.stationAddress?.message}
                        </span>
                      }
                    </Col>
                    <Col lg="2">
                      <label htmlFor="city" className="form-control-label">
                        City
                      </label>
                      <input
                        type="text"
                        placeholder="City"
                        className="form-control"
                        {...register("city")}
                      />
                      {
                        <span className="text-danger">
                          {errors.city?.message}
                        </span>
                      }
                    </Col>
                    <Col lg="3">
                      <label htmlFor="state" className="form-control-label">
                        State
                      </label>
                      <input
                        type="text"
                        placeholder="State"
                        className="form-control"
                        {...register("state")}
                      />
                      {
                        <span className="text-danger">
                          {errors.state?.message}
                        </span>
                      }
                    </Col>
                    <Col lg="3">
                      <label htmlFor="zip" className="form-control-label">
                        Zip
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Zip"
                        {...register("zip")}
                      />
                      {
                        <span className="text-danger">
                          {errors.zip?.message}
                        </span>
                      }
                    </Col>
                    <Col lg="3">
                      <label htmlFor="email" className="form-control-label">
                        Email
                      </label>
                      <input
                        type="email"
                        placeholder="Email"
                        className="form-control"
                        {...register("email")}
                      />
                      {
                        <span className="text-danger">
                          {errors.email?.message}
                        </span>
                      }
                    </Col>
                    <Col lg="3">
                      <label htmlFor="phone" className="form-control-label">
                        Phone
                      </label>
                      <input
                        type="text"
                        placeholder="Phone"
                        className="form-control"
                        {...register("phone")}
                      />
                      {
                        <span className="text-danger">
                          {errors.phone?.message}
                        </span>
                      }
                    </Col>
                    <Col lg="3">
                      <label
                        htmlFor="stationContact"
                        className="form-control-label"
                      >
                        Station Contact Person
                      </label>
                      <input
                        type="text"
                        placeholder="Station Contact Person"
                        className="form-control"
                        {...register("stationContact")}
                      />
                      {
                        <span className="text-danger">
                          {errors.stationContact?.message}
                        </span>
                      }
                    </Col>
                    <Col lg="3">
                      <label htmlFor="ATG Model" className="form-control-label">
                        ATG Model
                      </label>
                      <select
                        {...register("ATGModel")}
                        className="form-control"
                      >
                        <option value="tls350s">
                          VEEDER-ROOT TLS-350 SERIAL
                        </option>
                        <option value="tls450s">
                          VEEDER-ROOT TLS-450 SERIAL
                        </option>
                      </select>
                    </Col>
                    <Col lg="3">
                      <div className="d-flex flex-column justify-content-end">
                        <label
                          htmlFor="deviceId"
                          className="align-self-lg-end mb-2 form-control-label"
                          style={{
                            fontSize: "0.875rem",
                            fontWeight: "600",
                          }}
                        >
                          Device Gateway
                        </label>
                        <div className="align-self-lg-end">
                          <Badge
                            color={
                              getStation && getStation.gateway
                                ? "success"
                                : "danger"
                            }
                          >
                            {getStation && getStation.gateway
                              ? "Available"
                              : "Not Available"}
                          </Badge>
                        </div>
                      </div>{" "}
                    </Col>
                    {getStation && getStation?.gateway && (
                      <Col lg="3">
                        <label
                          htmlFor="deviceId"
                          className="form-control-label"
                        >
                          Device Identifier
                        </label>
                        <input
                          type="text"
                          placeholder="Device Identifier"
                          disabled
                          className="form-control"
                          value={getStation && getStation.deviceID}
                        />
                      </Col>
                    )}
                  </Row>
                </div>
                <hr />
                <h6 className="heading-small text-muted mb-4">
                  Station Additional Information
                </h6>
                <div className="px-lg-4">
                  <Row>
                    <Col lg="3">
                      <label
                        className="form-control-label"
                        htmlFor="facilityId"
                      >
                        Facility ID Number
                      </label>
                      <input
                        type="text"
                        placeholder="Facility ID Number"
                        className="form-control"
                        {...register("facilityId")}
                      />
                    </Col>
                    <Col lg="3">
                      <label
                        className="form-control-label"
                        htmlFor="input-ownerId"
                      >
                        Owner ID Number
                      </label>
                      <input
                        type="text"
                        placeholder="Owner ID Number"
                        className="form-control"
                        {...register("ownerId")}
                      />
                    </Col>
                    <Col lg="2">
                      <label className="form-control-label" htmlFor="ATGtype">
                        ATG Type
                      </label>
                      <input
                        type="text"
                        placeholder="ATG Type"
                        className="form-control"
                        {...register("ATGtype")}
                      />
                      {
                        <span className="text-danger">
                          {errors.ATGtype?.message}
                        </span>
                      }
                    </Col>
                    <Col lg="2">
                      <label
                        className="form-control-label"
                        htmlFor="consoleType"
                      >
                        Console Type
                      </label>
                      <input
                        type="text"
                        placeholder="Console Type"
                        className="form-control"
                        {...register("consoleType")}
                      />
                    </Col>
                    <Col lg="2">
                      <label
                        className="form-control-label"
                        htmlFor="dispenserType"
                      >
                        Dispenser Type
                      </label>
                      <input
                        type="text"
                        placeholder="Dispenser Type"
                        className="form-control"
                        {...register("dispenserType")}
                      />
                    </Col>
                  </Row>
                </div>
                <hr />
                <h6 className="heading-small text-muted mb-4">
                  SHIFT INFORMATION
                </h6>
                <div className="px-lg-4">
                  <Row>
                    {shiftFields.map((field, index) => {
                      console.log("field", index);
                      return (
                        <Col lg="3" key={field.id}>
                          <Card>
                            <CardHeader>
                              <div className="d-flex justify-content-between align-items-center">
                                <label
                                  htmlFor={`shift${index}`}
                                  className="form-control-label p-0 m-0"
                                >
                                  Shift #{`${index + 1}`}
                                </label>
                                <input
                                  type="checkbox"
                                  name={`shift[${index}].status`}
                                  {...register(`shift.${index}.status`)}
                                />
                              </div>
                            </CardHeader>
                            <CardBody>
                              <input
                                type="time"
                                className="form-control"
                                name={`shift.${index}.time`}
                                {...register(`shift[${index}].time`)}
                              />
                            </CardBody>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
                <hr />
                <h6 className="heading-small text-muted mb-4">
                  TANK INFORMATION
                </h6>
                <div className="pl-lg-4">
                  <Row>
                    {fields.map((field, index) => {
                      const foundIndex = findColorIndexInTankProductsGroup(
                        field.color
                      );

                      return (
                        <Col lg="4" key={field.id}>
                          <Card>
                            <CardHeader>
                              <div className="d-flex justify-content-between">
                                <label
                                  className="form-control-label"
                                  htmlFor={`tank${index}`}
                                >
                                  {`Tank #${index + 1}`}
                                </label>
                                {index > 0 && (
                                  <FontAwesomeIcon
                                    icon={faTimes}
                                    size="xl"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => remove(index)}
                                    color="red"
                                  />
                                )}
                              </div>
                            </CardHeader>
                            <CardBody>
                              <Controller
                                name={`tank[${index}].type`}
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                  <>
                                    <Select
                                      name={`tank[${index}.type]`}
                                      placeholder={"Product Type"}
                                      options={tankProductsGroup}
                                      styles={customStyles}
                                      isClearable={true}
                                      defaultValue={
                                        foundIndex &&
                                        tankProductsGroup[foundIndex.groupIndex]
                                          .options[foundIndex.optionIndex]
                                      }
                                      value={tankProductsGroup.find(
                                        (option) =>
                                          option.value?.fuelType === field.value
                                      )}
                                      onChange={(selectedOption) => {
                                        field.onChange(
                                          selectedOption
                                            ? selectedOption.value.fuelType
                                            : ""
                                        );
                                        // Set the color in the form data if an option is selected
                                        if (selectedOption) {
                                          const colorField = `tank[${index}].color`;
                                          setValue(
                                            colorField,
                                            selectedOption.value.color.value
                                          );
                                        }
                                      }}
                                    />
                                    {errors.tank &&
                                      errors.tank[index] &&
                                      errors.tank[index].type && (
                                        <span className="text-danger">
                                          {errors.tank[index].type.message}
                                        </span>
                                      )}
                                  </>
                                )}
                              />
                              <label
                                htmlFor={`tank.${index}.capacity`}
                                className="form-control-label"
                              ></label>
                              <input
                                type="text"
                                placeholder="Tank Capacity"
                                className="form-control"
                                {...register(`tank.${index}.capacity`, {
                                  required: "Tank capacity is required.",
                                })}
                              />
                              {errors.tank &&
                                errors.tank[index] &&
                                errors.tank[index].capacity && (
                                  <span className="text-danger">
                                    {errors.tank[index].capacity.message}
                                  </span>
                                )}
                              <Collapse isOpen={cardCollapseStates[index]}>
                                <label
                                  htmlFor={`tank.${index}.minRatio`}
                                ></label>
                                <input
                                  type="number"
                                  placeholder="Minimum Ratio"
                                  className="form-control"
                                  {...register(`tank.${index}.minRatio`)}
                                />
                                <label
                                  htmlFor={`tank.${index}.min_del`}
                                ></label>
                                <input
                                  type="number"
                                  placeholder="Minimum Delivery"
                                  className="form-control"
                                  {...register(`tank.${index}.min_del`)}
                                />
                                <label
                                  htmlFor={`tank.${index}.max_del`}
                                ></label>
                                <input
                                  type="number"
                                  placeholder="Maximum Delivery"
                                  className="form-control"
                                  {...register(`tank.${index}.max_del`)}
                                />
                              </Collapse>
                              <div
                                className="text-center"
                                style={{ paddingTop: "1rem" }}
                              >
                                <FontAwesomeIcon
                                  icon={
                                    cardCollapseStates[index]
                                      ? faChevronUp
                                      : faChevronDown
                                  }
                                  size="xl"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => toggle(index)}
                                  color="#172b4d"
                                />
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      );
                    })}
                    <Col lg="4">
                      <Card
                        style={{ height: "266px", cursor: "pointer" }}
                        onClick={() => append({ minRatio: 20 })}
                      >
                        <CardBody className="d-flex flex-column justify-content-center align-items-center">
                          <FontAwesomeIcon icon={faPlus} size="2x" />
                          Add Tank
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
                <Button type="submit" color="default" disabled={updateSLoading}>
                  Update Station
                </Button>
              </form>
            </CardBody>
          </Card>
        </Row>
      </Container>
    </>
  );
}

export default StationEdit;
