import React, { useEffect, useState } from "react";
// react plugin that prints a given react component
// react component for creating dynamic tables
// react component used to create sweet alerts
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import moment from "moment";
// work order component
import Select from "react-select";
import ReactDatetime from "react-datetime";
import { adminRoutes } from "../../../endpoints/base.endpoint";
import admin_routes from "../../../routes/admin.routes";

function ClientEmployeeAssignStation() {
  const [alert, setAlert] = React.useState(null);
  let newAssignStationValue = {};
  const [NewAssignStationObject, setNewAssignStationObject] = useState();
  const [employee, setEmployee] = useState();
  const [stationByOwner, setStationByOwner] = useState();
  const [disabledInput, setDisabledInput] = useState(true);
  const [employeeSelect, setEmployeeSelect] = useState(false);

  useEffect(() => {
    console.log(NewAssignStationObject);
  }, [NewAssignStationObject]);

  const handleNewWO = (typeofObj, value) => {
    newAssignStationValue[typeofObj] = value;
    setNewAssignStationObject((NewAssignStationObject) => ({
      ...NewAssignStationObject,
      ...newAssignStationValue,
    }));

    // set selected employee's details
    if (typeofObj === "id") {
      const selectedEmployee = EmployeeList.find(
        (employee) => employee.value._id === value
      );
      setEmployee(selectedEmployee.value);
    }
  };
  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  useEffect(() => {
    if (employee?.owner) {
      const filteredStation = StationList.filter(
        (station) => station.value.owner === employee.owner
      );
      setStationByOwner(filteredStation);
      setDisabledInput(false);
    } else {
      setStationByOwner();
      setDisabledInput(true);
    }
  }, [employee]);

  const [
    { data: getStation, loading: getStationLoading, error: getStationError },
    refreshgetStation,
  ] = adminRoutes(
    {
      url: "getAllStations",
      method: "GET",
      headers: config.header,
    },
    { manual: false }
  );

  let StationList = [];
  let assignEmployeeValue = {};
  const [assignEmployeeObject, setassignEmployeeObject] = useState();

  if (getStation) {
    getStation.data.map((data, idx) =>
      StationList.push({ value: data, label: data.stationName })
    );
  }

  const [
    { data: getEmployee, loading: getEmployeeLoading, error: getEmployeeError },
    refreshEmployee,
  ] = adminRoutes(
    {
      url: "getAllClientEmployee",
      method: "GET",
      headers: config.header,
    },
    { manual: false }
  );

  let EmployeeList = [];
  if (getEmployee) {
    getEmployee.data.map((data, idx) =>
      EmployeeList.push({ value: data, label: data.name })
    );
  }

  const [
    { data: createAssignStation, loading: createOLoading, error: createOError },
    createAssignStationRequest,
  ] = adminRoutes(
    {
      url: "assignStationToClientEmployee",
      method: "POST",
      headers: config.header,
      data: NewAssignStationObject,
    },
    { manual: true }
  );

  function handleNewAssignStationSubmit() {
    createAssignStationRequest().then((res) => {
      console.log(res);
      console.log("redirect");
      window.location.href = "/admin/client-employee-list";
    });
  }

  return (
    <>
      {alert}
      <SimpleHeader name="Assign Employee To Station" parentName="Client" />
      <Container className="mt--6" fluid>
        <Card>
          {/* <CardHeader className="bg-transparent">
            <h3 className="mb-0 ">Assign Employee To Station</h3>
          </CardHeader> */}
          <CardBody>
            <Form>
              <h6 className="heading-small text-muted mb-4">
                Station And Owner information
              </h6>
              <div className="px-lg-4">
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="u_id">
                        Employee To Assign
                      </label>
                      <Select
                        options={EmployeeList}
                        id={"Employee"}
                        onChange={(e) => {
                          handleNewWO("id", e.value._id);
                          setEmployeeSelect(true);
                        }}
                      />
                      {!employeeSelect && (
                        <div className="text-danger mt-2 font-weight-bold">
                          Please select an employee
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="u_id">
                        Station To Assign
                      </label>
                      <Select
                        isDisabled={disabledInput}
                        options={stationByOwner}
                        id={"station"}
                        onChange={(e) => {
                          handleNewWO("station_id", e.value._id);
                        }}
                      />
                      {(disabledInput || StationList.length === 0) &&
                        employeeSelect && (
                          <div className="mt-2 text-danger font-weight-bold">
                            No stations available to assign
                          </div>
                        )}
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </Form>
          </CardBody>
          <CardFooter>
            <Button
              color="default"
              onClick={handleNewAssignStationSubmit}
              disabled={createOLoading}
            >
              Assign Employee
            </Button>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
}

export default ClientEmployeeAssignStation;
