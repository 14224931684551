import { useEffect, useState } from "react";
// react plugin that prints a given react component
// react component for creating dynamic tables
// react component used to create sweet alerts
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
// work order component
import Select from "react-select";
import { adminRoutes } from "../../../endpoints/base.endpoint";

function ClientUserAssignStation() {
  const [alert, setAlert] = useState(null);
  let newAssignStationValue = {};
  const [NewAssignStationObject, setNewAssignStationObject] = useState();
  const [assignedClient, setAssignedClient] = useState("");

  useEffect(() => {
    console.log(NewAssignStationObject);
  }, [NewAssignStationObject]);

  const handleNewWO = (typeofObj, value) => {
    newAssignStationValue[typeofObj] = value;
    setNewAssignStationObject((NewAssignStationObject) => ({
      ...NewAssignStationObject,
      ...newAssignStationValue,
    }));
    console.log(NewAssignStationObject);
  };
  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  const [
    { data: getStation, loading: getStationLoading, error: getStationError },
    refreshgetStation,
  ] = adminRoutes(
    {
      url: "getAllStations",
      method: "GET",
      headers: config.header,
    },
    { manual: false }
  );

  let StationList = [];
  let assignUserValue = {};
  const [assignUserObject, setassignUserObject] = useState();

  if (getStation) {
    getStation.data.map((data, idx) =>
      StationList.push({ value: data, label: data.stationName })
    );
  }

  const [
    { data: getUser, loading: getUserLoading, error: getUserError },
    refreshUser,
  ] = adminRoutes(
    {
      url: "getAllClientUser",
      method: "GET",
      headers: config.header,
    },
    { manual: false }
  );

  let UserList = [];
  if (getUser) {
    getUser.map((data, idx) =>
      UserList.push({ value: data, label: data.name })
    );
  }

  useEffect(() => {
    if (NewAssignStationObject) {
      const selectedStation = StationList.find(
        (station) => station.value._id === NewAssignStationObject.station_id
      );

      let alreadyAssignedUser = "";

      if (
        selectedStation &&
        selectedStation.value &&
        selectedStation.value.owner
      ) {
        alreadyAssignedUser = UserList.find(
          (user) => user.value._id === selectedStation.value.owner
        );
      }

      if (alreadyAssignedUser !== "") {
        setAssignedClient(alreadyAssignedUser.label);
      } else {
        setAssignedClient("");
      }
    }
  }, [NewAssignStationObject]);

  const [
    { data: createAssignStation, loading: createOLoading, error: createOError },
    createAssignStationRequest,
  ] = adminRoutes(
    {
      url: "assignStationToClientUser",
      method: "POST",
      headers: config.header,
      data: NewAssignStationObject,
    },
    { manual: true }
  );

  function handleNewAssignStationSubmit() {
    createAssignStationRequest().then((res) => {
      console.log(res);
      console.log("redirect");
      window.location.href = "/admin/client-user-list";
    });
  }

  return (
    <>
      {alert}
      <SimpleHeader name="Assign Owner To Station" parentName="Client" />
      <Container className="mt--6" fluid>
        <Card>
          {/* <CardHeader className="bg-transparent">
            <h3 className="mb-0 ">Assign Owner To Station</h3>
          </CardHeader> */}
          <CardBody>
            <Form>
              <h6 className="heading-small text-muted mb-4">
                Station And Owner information
              </h6>
              <div className="px-lg-4">
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="u_id">
                        Station To Assign
                      </label>
                      <Select
                        options={StationList}
                        id={"station"}
                        onChange={(e) => {
                          handleNewWO("station_id", e.value._id);
                        }}
                      />
                    </FormGroup>
                    {assignedClient && (
                      <div>
                        <span className="font-weight-bold">
                          Assigned Owner:
                        </span>{" "}
                        {assignedClient}
                      </div>
                    )}
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="u_id">
                        Owner To Assign
                      </label>
                      <Select
                        options={UserList}
                        id={"User"}
                        onChange={(e) => {
                          handleNewWO("id", e.value._id);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </Form>
          </CardBody>
          <CardFooter>
            <Button
              color="default"
              onClick={handleNewAssignStationSubmit}
              disabled={createOLoading}
            >
              Assign Owner
            </Button>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
}

export default ClientUserAssignStation;
