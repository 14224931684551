// importing Pages
import Login from "../views/pages/login/Login.js";

//importing manager routes access level 1
import admin_routes from "./admin.routes";

const routes_base = [
  {
    path: "/login",
    name: "Login",
    miniName: "L",
    invisible: true,
    component: Login,
    layout: "/auth",
  },
];
const routes = routes_base.concat(admin_routes);
export default routes;