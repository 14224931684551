import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  Row,
} from "reactstrap";
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { adminRoutes } from "../../../endpoints/base.endpoint";
import * as adminEndpoints from "../../../endpoints/admin.endpoint";

function VendorManagerEdit() {
  const vendorMangerId = sessionStorage.getItem("vendor_manager_edit_id");
  const [isActiveModal, setisActiveModal] = useState(false);
  const [accountStatus, setAccountStatus] = useState();
  const [NewManagerObject, setNewManagerObject] = useState();
  let newManagerValue = {};

  useEffect(() => {
    console.log(NewManagerObject);
  }, [NewManagerObject]);

  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  let Routes;
  let endpoints;

  Routes = adminRoutes;
  endpoints = adminEndpoints;

  // GET SELECTED MANAGER DETAIL
  const [
    {
      data: getVendorManager,
      loading: getVendorManagerLoading,
      error: getVendorManagerError,
    },
    refreshManager,
  ] = Routes({
    url: endpoints.view_vendor_manager.path + "/" + vendorMangerId,
    method: endpoints.view_vendor_manager.method,
    headers: config.header,
    data: {
      id: vendorMangerId,
    },
  });

  if (getVendorManager) {
    newManagerValue = getVendorManager;
    delete newManagerValue._id;
    delete newManagerValue.engineers;
    delete newManagerValue.stations;
    delete newManagerValue.createdAt;
    delete newManagerValue.updatedAt;
  }

  const handleEditVM = (typeofObj, value) => {
    newManagerValue[typeofObj] = value;
    setNewManagerObject((NewManagerObject) => ({
      ...NewManagerObject,
      ...newManagerValue,
    }));
    console.log(NewManagerObject);
  };

  // update button api call
  const [
    { loading: updateVendorManagerLoading, error: updateVendorManagerError },
    updateVendorManager,
  ] = Routes(
    {
      url: endpoints.edit_vendor_manager.path,
      method: endpoints.edit_vendor_manager.method,
      headers: config.header,
      data: { manager_id: vendorMangerId, ...NewManagerObject },
    },
    { manual: true }
  );

  // ACCOUNT STATUS UPDATE API
  const [{ loading, error }, changeStatus] = adminRoutes(
    {
      url: adminEndpoints.change_vendor_manager_status.path,
      method: adminEndpoints.change_vendor_manager_status.method,
      headers: config.header,
      data: {
        id: vendorMangerId,
        status: accountStatus,
      },
    },
    { manual: true }
  );

  function handleUpdateVendorManagerSubmit() {
    updateVendorManager()
      .then((res) => {
        if (res.status === 200) {
          window.location.href = "/admin/manager-list";
        }
      })
      .catch((err) => console.log("err", err));
  }

  //TOOGLE MODAL
  function closeModal() {
    if (isActiveModal) {
      setisActiveModal(false);
    } else {
      setisActiveModal(true);
    }
  }

  // HANDLE ACCOUNT ACITVE SUBMIT
  function handleStatusSubmit() {
    console.log("account status", accountStatus);
    closeModal();
    changeStatus()
      .then((res) => {
        console.log("status", res);
        refreshManager();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      <SimpleHeader name="Vendor Manager Edit" parentName="Manager" />
      <Modal
        className="modal-dialog-centered w-100 h-100"
        backdrop={true}
        size="md"
        centered={true}
        scrollable={true}
        isOpen={isActiveModal}
        toggle={closeModal}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Active/Deactive Account
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <div>
              <Row>
                <Col lg="4">
                  <FormGroup>
                    <div
                      className="mb-2"
                      style={{ fontSize: "0.875rem", fontWeight: "600" }}
                    >
                      Is Active?
                    </div>
                    <label className="custom-toggle">
                      <input
                        defaultChecked={
                          getVendorManager && getVendorManager.isActive
                        }
                        type="checkbox"
                        onChange={(e) => setAccountStatus(e.target.checked)}
                      />
                      <span className="custom-toggle-slider rounded-circle" />
                    </label>
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="modal-footer">
          <Button color="danger" onClick={handleStatusSubmit}>
            Submit
          </Button>{" "}
          <Button onClick={closeModal}>Cancel</Button>
        </div>
      </Modal>
      <Container className="mt--6" fluid>
        <Card>
          {/* <CardHeader className="bg-transparent d-flex align-items-center justify-content-between">
            <h3 className="mb-0 ">Vendor Manager Edit</h3>
            <button className="btn btn-warning btn-sm" onClick={closeModal}>
              Status
            </button>
          </CardHeader> */}
          <CardBody>
            <Form>
              <div className="bg-transparent d-flex align-items-center justify-content-between">
                <a href="./manager-list" className="text-white mb-3">
                  <Button size="sm" color="default" type="button">
                    Back
                  </Button>
                </a>
                <button className="btn btn-warning btn-sm" onClick={closeModal}>
                  Status
                </button>
              </div>
              <h6 className="heading-small text-muted mb-4">
                Manager Information
              </h6>
              <div className="px-lg-4">
                {getVendorManager && (
                  <Row>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-manager-name"
                        >
                          Manager Name
                        </label>
                        <Input
                          id="input-manager-name"
                          type={"text"}
                          defaultValue={getVendorManager.name}
                          placeholder={"Manager Name"}
                          onChange={(e) => {
                            handleEditVM("name", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-manager-email"
                        >
                          Manager Email
                        </label>
                        <Input
                          id="input-manager-email"
                          type={"text"}
                          placeholder={"Manager Email"}
                          defaultValue={getVendorManager.email}
                          onChange={(e) => {
                            handleEditVM("email", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-manager-phone"
                        >
                          Manager Phone
                        </label>
                        <Input
                          id="input-manager-phone"
                          type={"tel"}
                          defaultValue={getVendorManager.phone}
                          placeholder={"Manager phone"}
                          onChange={(e) => {
                            handleEditVM("phone", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-company-name"
                        >
                          Company Name
                        </label>
                        <Input
                          id="input-company-name"
                          type={"text"}
                          placeholder={"Company Name"}
                          defaultValue={getVendorManager.companyName}
                          onChange={(e) => {
                            handleEditVM("companyName", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-companay-address"
                        >
                          Company Address
                        </label>
                        <Input
                          id="input-companay-address"
                          type={"text"}
                          placeholder={"Company Address"}
                          defaultValue={getVendorManager.companyAddress}
                          onChange={(e) => {
                            handleEditVM("companyAddress", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="company-city"
                        >
                          Company City
                        </label>
                        <Input
                          id="company-city"
                          rows="6"
                          type={"text"}
                          placeholder={"Company City"}
                          defaultValue={getVendorManager.companyCity}
                          onChange={(e) => {
                            handleEditVM("companyCity", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="company-state"
                        >
                          Company State
                        </label>
                        <Input
                          id="company-state"
                          rows="6"
                          type={"text"}
                          placeholder={"Company State"}
                          defaultValue={getVendorManager.companyState}
                          onChange={(e) => {
                            handleEditVM("companyState", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="company-country"
                        >
                          Company Country
                        </label>
                        <Input
                          id="company-country"
                          rows="6"
                          type={"text"}
                          placeholder={"Company Country"}
                          defaultValue={getVendorManager.companyCountry}
                          onChange={(e) => {
                            handleEditVM("companyCountry", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="4">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="company-zip"
                        >
                          Company Zip
                        </label>
                        <Input
                          id="company-zip"
                          rows="6"
                          type={"text"}
                          placeholder={"Company Zip"}
                          defaultValue={getVendorManager.companyZip}
                          onChange={(e) => {
                            handleEditVM("companyZip", e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                )}
              </div>
            </Form>
          </CardBody>
          <CardFooter>
            <Button color="default" onClick={handleUpdateVendorManagerSubmit}>
              Update Vendor Manager
            </Button>
            <a className="btn btn-danger" href="./manager-list">
              Cancel
            </a>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
}

export default VendorManagerEdit;
