import React, { useEffect, useState } from "react";
// react plugin that prints a given react component
// react component for creating dynamic tables
// react component used to create sweet alerts
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import moment from "moment";
// work order component
import Select from "react-select";
import ReactDatetime from "react-datetime";
import { adminRoutes } from "../../../endpoints/base.endpoint";
import * as adminEndpoints from "../../../endpoints/admin.endpoint";

function OnboardStation() {
  const [alert, setAlert] = React.useState(null);
  const [toggleSubmitBtn, setToggleSubmitBtn] = useState(true);
  const [allStations, setAllStations] = useState([]);
  const [deviceIdError, setDeviceIdError] = useState();
  const [deviceIdStatus, setDeviceIdStatus] = useState({
    message: "",
    exists: false,
  });
  let newStationValue = {};
  const [NewStationObject, setNewStationObject] = useState({
    ATGModel: "tls350s",
    stationName: "",
    deviceID: "",
  });

  const [inputErrors, setInputErrors] = useState({
    stationNameError: "",
    deviceIdError: "",
  });

  useEffect(() => {
    console.log(NewStationObject);
  }, [NewStationObject]);

  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  // GET ALL STATION API
  const [{ data, loading, error }] = adminRoutes({
    url: adminEndpoints.list_every_station.path,
    method: adminEndpoints.list_every_station.method,
    headers: config.header,
  });

  useEffect(() => {
    if (data) {
      setAllStations(data);
    }
  }, [data]);

  // SUBMIT VALIDATION BASED ON DEVICE ID
  useEffect(() => {
    if (NewStationObject.deviceID.length === 12 && !duplicateDeviceId) {
      setToggleSubmitBtn(false);
    } else {
      setToggleSubmitBtn(true);
    }
  }, [NewStationObject.deviceID]);

  const [
    { data: createStation, loading: createOLoading, error: createOError },
    createStationRequest,
  ] = adminRoutes(
    {
      url: adminEndpoints.onboard_station.path,
      method: adminEndpoints.onboard_station.method,
      headers: config.header,
      data: NewStationObject,
    },
    { manual: true },
  );

  const handleNewS = (typeofObj, value) => {
    newStationValue[typeofObj] = value;
    setNewStationObject((NewStationObject) => ({
      ...NewStationObject,
      ...newStationValue,
    }));

    if (typeofObj === "stationName") {
      if (value.length > 0) {
        setInputErrors({ ...inputErrors, stationNameError: "" });
      } else {
        setInputErrors({
          ...inputErrors,
          stationNameError: "Station name required!",
        });
      }
    } else if (typeofObj === "deviceID") {
      if (value.length > 0)
        setInputErrors({ ...inputErrors, deviceIdError: "" });
      else
        setInputErrors({
          ...inputErrors,
          deviceIdError: "Device Identifier required!",
        });
    }

    console.log(NewStationObject);
  };

  let duplicateDeviceId;

  if (NewStationObject.deviceID) {
    duplicateDeviceId = allStations.some(
      (station) => station.deviceID === NewStationObject.deviceID,
    );
  }

  console.log("duplicate", duplicateDeviceId);

  function handleNewStationSubmit() {
    const errors = {};

    if (NewStationObject.stationName === "") {
      errors.stationNameError = "Station name required!";
    }
    if (NewStationObject.deviceID === "") {
      errors.deviceIdError = "Device identifier required!";
    }

    if (!errors.stationNameError && !errors.deviceIdError) {
      createStationRequest().then((res) => {
        console.log(res);
        console.log("redirect");
        if (res.data) {
          sessionStorage.setItem("onboarding_id", res.data.data._id);
          console.log("data", res.data.data);
          window.location.href = "/admin/complete-onboarding";
        }
        console.log("onboarding_id", res.data.data._id);
      });
    }
    setInputErrors(errors);
  }

  return (
    <>
      {alert}
      <SimpleHeader name="Automatic Onboard" parentName="Station" />
      <Container className="mt--6" fluid>
        <Card>
          {/* <CardHeader className="bg-transparent">
            <h3 className="mb-0 ">Onboard Station</h3>
          </CardHeader> */}
          <CardBody>
            <Form>
              <h6 className="heading-small text-muted mb-4">
                Station Basic Information
              </h6>
              <div className="px-lg-4">
                <Row>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-station-name"
                      >
                        Station Name
                        <span className="text-danger font-weight-900">*</span>
                      </label>
                      <Input
                        id="input-station-name"
                        rows="6"
                        className={
                          inputErrors.stationNameError ? "is-invalid" : ""
                        }
                        type={"text"}
                        placeholder={"Station Name"}
                        onChange={(e) => {
                          handleNewS("stationName", e.target.value);
                        }}
                        required
                      />
                      {inputErrors.stationNameError && (
                        <div className="text-danger">
                          Station Name is required
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-device-identifier"
                      >
                        Device Identifier
                        <span className="text-danger font-weight-900">*</span>
                      </label>
                      <Input
                        id="input-device-identifier"
                        rows="6"
                        type={"text"}
                        className={
                          inputErrors.deviceIdError ? "is-invalid" : ""
                        }
                        placeholder={"Device Identifier"}
                        maxLength={"12"}
                        onChange={(e) => {
                          let inputValue = e.target.value;
                          inputValue = inputValue.replace(/[^A-Za-z0-9]/g, "");
                          inputValue = inputValue.toUpperCase();
                          handleNewS("deviceID", inputValue);
                          if (
                            e.target.value.toLowerCase() !==
                            inputValue.toLowerCase()
                          ) {
                            // Convert to lowercase before comparison
                            setDeviceIdError(
                              "Only letters and numbers are allowed.",
                            );
                          } else {
                            setDeviceIdError("");
                          }
                        }}
                        required
                      />
                      {inputErrors.deviceIdError && (
                        <div className="text-danger">
                          Device Identifier is required
                        </div>
                      )}
                      {deviceIdError && (
                        <div className="text-danger">{deviceIdError}</div>
                      )}
                      {duplicateDeviceId && (
                        <div className="text-danger">
                          Device Identifier already exists!
                        </div>
                      )}
                      {deviceIdStatus.message && (
                        <div
                          className={
                            deviceIdStatus.exists
                              ? "text-danger"
                              : "text-success"
                          }
                        >
                          {deviceIdStatus.message}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="ATGModel">
                        ATG Model
                      </label>
                      <Input
                        type="select"
                        id="ATGModel"
                        rows="6"
                        value={NewStationObject.ATGModel}
                        onChange={(e) => {
                          handleNewS("ATGModel", e.target.value);
                        }}
                      >
                        <option value="tls350S">
                          VEEDER-ROOT TLS-350 SERIAL
                        </option>
                        <option value="tls450S">
                          VEEDER-ROOT TLS-450 SERIAL
                        </option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </Form>
          </CardBody>
          <CardFooter>
            <Button
              color="default"
              onClick={handleNewStationSubmit}
              disabled={toggleSubmitBtn}
            >
              Submit
            </Button>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
}

export default OnboardStation;
