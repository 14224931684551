import React, { useEffect, useState } from "react";
// react plugin that prints a given react component
// react component for creating dynamic tables
// react component used to create sweet alerts
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import moment from "moment";
// work order component
import Select from "react-select";
import ReactDatetime from "react-datetime";
import { adminRoutes } from "../../../endpoints/base.endpoint";
import * as adminEndpoints from "../../../endpoints/admin.endpoint";

function ClientUserCreate() {
  const [alert, setAlert] = React.useState(null);
  let newClientUserValue = {};
  const [NewClientUserObject, setNewClientUserObject] = useState();

  useEffect(() => {
    console.log(NewClientUserObject);
  }, [NewClientUserObject]);

  const handleNewS = (typeofObj, value) => {
    newClientUserValue[typeofObj] = value;
    setNewClientUserObject((NewClientUserObject) => ({
      ...NewClientUserObject,
      ...newClientUserValue,
    }));
    console.log(NewClientUserObject);
  };
  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  const [
    { data: createClientUser, loading: createOLoading, error: createOError },
    createClientUserRequest,
  ] = adminRoutes(
    {
      url: "createClientUser",
      method: "POST",
      headers: config.header,
      data: NewClientUserObject,
    },
    { manual: true }
  );

  function handleNewClientUserSubmit() {
    createClientUserRequest().then((res) => {
      console.log(res);
      console.log("redirect");
      window.location.href = "/admin/client-user-list";
    });
  }

  return (
    <>
      {alert}
      <SimpleHeader name="Create Owner" parentName="Client" />
      <Container className="mt--6" fluid>
        <Card>
          {/* <CardHeader className="bg-transparent">
            <h3 className="mb-0 ">Create Owner</h3>
          </CardHeader> */}
          <CardBody>
            <Form>
              <h6 className="heading-small text-muted mb-4">
                Owner Basic Information
              </h6>
              <div className="px-lg-4">
                <Row>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-user-name"
                      >
                        Name
                      </label>
                      <Input
                        id="input-user-name"
                        rows="6"
                        type={"text"}
                        placeholder={"Name"}
                        onChange={(e) => {
                          handleNewS("name", e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-user-email"
                      >
                        Email
                      </label>
                      <Input
                        id="input-user-email"
                        type={"text"}
                        placeholder={"Owner Email"}
                        onChange={(e) => {
                          handleNewS("email", e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-user-password"
                      >
                        Password
                      </label>
                      <Input
                        id="input-user-password"
                        rows="6"
                        type={"text"}
                        placeholder={"Password"}
                        onChange={(e) => {
                          handleNewS("password", e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-station-phone"
                      >
                        Phone
                      </label>
                      <Input
                        id="input-station-phone"
                        rows="6"
                        type={"text"}
                        placeholder={"Phone"}
                        onChange={(e) => {
                          handleNewS("phone", e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
              <hr className="my-4" />

              <h6 className="heading-small text-muted mb-4">
                Owner Company Information
              </h6>
              <div className="px-lg-4">
                <Row>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-company-name"
                      >
                        Company Name
                      </label>
                      <Input
                        id="input-company-name"
                        rows="6"
                        type={"text"}
                        placeholder={"Company Name"}
                        onChange={(e) => {
                          handleNewS("companyName", e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-company-address"
                      >
                        Company Address
                      </label>
                      <Input
                        id="input-company-address"
                        rows="6"
                        type={"text"}
                        placeholder={"Company Address"}
                        onChange={(e) => {
                          handleNewS("companyAddress", e.target.value);
                        }}
                        maxLength={"50"}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="company-city"
                      >
                        Company City
                      </label>
                      <Input
                        id="company-city"
                        rows="6"
                        type={"text"}
                        placeholder={"Company City"}
                        onChange={(e) => {
                          handleNewS("companyCity", e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="company-state"
                      >
                        Company State
                      </label>
                      <Input
                        id="company-state"
                        rows="6"
                        type={"text"}
                        placeholder={"Company State"}
                        onChange={(e) => {
                          handleNewS("companyState", e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="company-country"
                      >
                        Company Country
                      </label>
                      <Input
                        id="company-country"
                        rows="6"
                        type={"text"}
                        placeholder={"Company Country"}
                        onChange={(e) => {
                          handleNewS("companyCountry", e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="company-zip"
                      >
                        Company Zip
                      </label>
                      <Input
                        id="company-zip"
                        rows="6"
                        type={"text"}
                        placeholder={"Company Zip"}
                        onChange={(e) => {
                          handleNewS("companyZip", e.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </Form>
          </CardBody>
          <CardFooter>
            <Button
              color="default"
              onClick={handleNewClientUserSubmit}
              disabled={createOLoading}
            >
              Create Owner
            </Button>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
}

export default ClientUserCreate;
