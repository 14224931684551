import CustomProfileHeader from "components/Headers/CustomProfileHeader";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  Row,
} from "reactstrap";
import { adminRoutes } from "../../../endpoints/base.endpoint";
import * as adminEndpoints from "../../../endpoints/admin.endpoint";

function VendorEngineerDetails() {
  const emp_id = sessionStorage.getItem("vendor_engineer_manage_id");
  const [unassignVendorEmployeeModal, setUnassignVendorEmployeeModal] =
    useState(false);
  const [unassignConfirmation, setUnassignConfirmation] = useState("");
  const [confirmationError, setConfirmationError] = useState("");

  useEffect(() => {
    if (unassignConfirmation.length !== 7) {
      setConfirmationError("Please type confirm");
    } else if (unassignConfirmation !== "confirm") {
      setConfirmationError("Please type confirm");
    } else {
      setConfirmationError("");
    }
  }, [unassignConfirmation]);

  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  let Routes;
  let endpoints;
  Routes = adminRoutes;
  endpoints = adminEndpoints;

  // VENODR ENGINEER DETAILS API
  const [
    {
      data: getVendorEmployee,
      loading: getVendorEmployeeLoading,
      error: getVendorEmployeeError,
    },
    refreshVendorEmployee,
  ] = Routes({
    url: endpoints.view_vendor_engineer.path + "/" + emp_id,
    method: endpoints.view_vendor_engineer.method,
    headers: config.header,
  });

  const [
    {
      loading: unassignVendorEmployeeLoading,
      error: unasignVendorEmployeeError,
    },
    unassignVendorEmpoyee,
  ] = Routes(
    {
      url: endpoints.unassign_vendor_engineer_from_manager.path,
      method: endpoints.unassign_vendor_engineer_from_manager.method,
      headers: config.header,
      data: {
        id: emp_id,
      },
    },
    { manual: true }
  );

  function closeModal() {
    if (unassignVendorEmployeeModal) {
      setUnassignVendorEmployeeModal(false);
    } else {
      setUnassignVendorEmployeeModal(true);
    }
  }

  function handleUnassignSubmit() {
    closeModal();
    unassignVendorEmpoyee()
      .then((data) => {
        console.log("data", data);
        refreshVendorEmployee();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  return (
    <>
      <Modal
        className="modal-dialog-centered w-100 h-100"
        backdrop={true}
        size="md"
        centered={true}
        scrollable={true}
        isOpen={unassignVendorEmployeeModal}
        toggle={closeModal}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Unassign Employee Confirmation
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <div>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-unassign-confirmation"
                    >
                      Type confirm to unassign employee
                    </label>
                    <Input
                      id="input-unassign-confirmation"
                      className={confirmationError ? "is-invalid" : ""}
                      type={"text"}
                      placeholder={"confirm"}
                      maxLength={7}
                      onChange={(e) => setUnassignConfirmation(e.target.value)}
                    />
                    {confirmationError && (
                      <div className="text-danger">{confirmationError}</div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="modal-footer">
          <Button
            color="danger"
            onClick={handleUnassignSubmit}
            disabled={confirmationError ? true : false}
          >
            Submit
          </Button>{" "}
          <Button onClick={closeModal}>Cancel</Button>
        </div>
      </Modal>
      <CustomProfileHeader heading={"Vendor Engineer Details"} />
      {getVendorEmployee && (
        <Container className="mt--6" fluid>
          <Row>
            <Col className="order-lg-1" lg="12">
              <Card className="card-profile">
                <CardHeader className="text-center border-0 pt-4 pt-md-4 pb-0 pb-md-4">
                  <div className="row align-items-center">
                    <div className="col-sm-2 col-md-2 col-lg-1">
                      <a
                        href="./employee-list"
                        className="text-white float-left"
                      >
                        <Button size="sm" color="default" type="button">
                          Back
                        </Button>
                      </a>
                    </div>
                    <div className="col-sm-8 col-md-8 col-lg-10">
                      <div className="display-1 capitalize">
                        {getVendorEmployee.emp.name}
                      </div>
                    </div>
                    <div className="col-sm-2 col-md-2 col-lg-1 align-self-center">
                      <a
                        href={"./vendor-engineer-edit"}
                        className="btn btn-warning d-print-none"
                        onClick={(e) =>
                          sessionStorage.setItem(
                            "vendor_engineer_edit_id",
                            emp_id
                          )
                        }
                      >
                        Edit
                      </a>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <Row>
                    <div className="col-12">
                      <div className="card-profile-stats d-flex justify-content-center"></div>
                    </div>
                  </Row>
                  <Row className="p-4">
                    <Col lg="6" className={"h3 mt-2"}>
                      <span className={"h4 font-weight-light p-1"}>
                        Email :{" "}
                        <a href={"mailto:"}>{getVendorEmployee.emp.email}</a>
                      </span>
                    </Col>
                    <Col lg="6" className={"h3 mt-2"}>
                      <span className={"h4 font-weight-light p-1"}>
                        Phone :{" "}
                        <a href={"tel:"}>{getVendorEmployee.emp.phone}</a>
                      </span>
                    </Col>
                  </Row>
                  <hr />
                  {getVendorEmployee.manager && (
                    <>
                      <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-center">
                        <h6 className="heading-small text-muted align-middle">
                          Assigned Manager Details
                        </h6>
                        <button
                          onClick={closeModal}
                          className="btn btn-danger btn-sm d-print-none"
                        >
                          Unassign
                        </button>
                      </div>
                      <Row className="px-4">
                        <Col lg="6" className={"h3 mt-2"}>
                          <span className={"h4 font-weight-light p-1"}>
                            Manager Name : {getVendorEmployee.manager.name}
                          </span>
                        </Col>
                        <Col lg="6" className={"h3 mt-2"}>
                          <span className={"h4 font-weight-light p-1"}>
                            Manager Email : {getVendorEmployee.manager.email}
                          </span>
                        </Col>
                      </Row>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default VendorEngineerDetails;
