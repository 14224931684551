import React, { useEffect, useState } from "react";
// react plugin that prints a given react component
// react component for creating dynamic tables
// react component used to create sweet alerts
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
// work order component
import Select from "react-select";
import { adminRoutes } from "../../../endpoints/base.endpoint";
import * as adminEndpoints from "../../../endpoints/admin.endpoint";

function JobberAssignStation() {
  let newAssignJobberValue = {};
  const [NewAssignJobberObject, setNewAssignJobberObject] = useState();

  useEffect(() => {
    console.log(NewAssignJobberObject);
  }, [NewAssignJobberObject]);

  const handleNewWO = (typeofObj, value) => {
    newAssignJobberValue[typeofObj] = value;
    setNewAssignJobberObject((NewAssignJobberObject) => ({
      ...NewAssignJobberObject,
      ...newAssignJobberValue,
    }));
    console.log(NewAssignJobberObject);
  };

  let Routes;
  let endpoints;

  Routes = adminRoutes;
  endpoints = adminEndpoints;

  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  const [
    { data: getStation, loading: getStationLoading, error: getStationError },
    refreshgetStation,
  ] = Routes(
    {
      url: endpoints.list_station.path,
      method: endpoints.list_station.method,
      headers: config.header,
    },
    { manual: false }
  );

  let StationList = [];

  if (getStation) {
    getStation.data.map((data, idx) =>
      StationList.push({ value: data, label: data.stationName })
    );
  }

  const [
    { data: getJobbers, loading: getJobbersLoading, error: getJobbersError },
    refreshJobber,
  ] = Routes(
    {
      url: endpoints.list_jobber.path,
      method: endpoints.list_jobber.method,
      headers: config.header,
    },
    { manual: false }
  );

  let JobberList = [];
  if (getJobbers) {
    getJobbers.map((data, idx) =>
      JobberList.push({ value: data, label: data.name })
    );
  }

  const [
    { data: createAssignStation, loading: createOLoading, error: createOError },
    assignJobberToStation,
  ] = Routes(
    {
      url: endpoints.assign_jobber.path,
      method: endpoints.assign_jobber.method,
      headers: config.header,
      data: NewAssignJobberObject,
    },
    { manual: true }
  );

  function handleNewAssignJobberSubmit() {
    assignJobberToStation().then((res) => {
      console.log(res);
      console.log("redirect");
      window.location.href = "/admin/jobber-list";
    });
  }

  return (
    <>
      {alert}
      <SimpleHeader name="Assign Jobber To Station" parentName="Jobber" />
      <Container className="mt--6" fluid>
        <Card>
          {/* <CardHeader className="bg-transparent">
            <h3 className="mb-0 ">Assign Jobber To Station</h3>
          </CardHeader> */}
          <CardBody>
            <Form>
              <h6 className="heading-small text-muted mb-4">
                Station And Jobber information
              </h6>
              <div className="px-lg-4">
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="u_id">
                        Station To Assign
                      </label>
                      <Select
                        options={StationList}
                        id={"station"}
                        onChange={(e) => {
                          handleNewWO("sid", e.value._id);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label" htmlFor="u_id">
                        Jobber To Assign
                      </label>
                      <Select
                        options={JobberList}
                        id={"Jobber"}
                        onChange={(e) => {
                          handleNewWO("jid", e.value._id);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </Form>
          </CardBody>
          <CardFooter>
            <Button color="default" onClick={handleNewAssignJobberSubmit}>
              Assign Jobber
            </Button>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
}

export default JobberAssignStation;
