import React, { useCallback, useEffect, useState } from "react";
import { ChonkyActions, FullFileBrowser, setChonkyDefaults } from "chonky";
import { ChonkyIconFA } from "chonky-icon-fontawesome";
import {
  DeleteObjectCommand,
  GetObjectCommand,
  PutObjectCommand,
} from "@aws-sdk/client-s3";
import path from "path-browserify";
import { BUCKET_NAME, s3Client } from "../../../clients/S3_client";

import fileDownload from "js-file-download";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
} from "reactstrap";

import SimpleHeader from "../../../components/Headers/SimpleHeader";
import { FormGroup } from "reactstrap/lib";
import { adminRoutes } from "../../../endpoints/base.endpoint";
import * as adminEndpoints from "../../../endpoints/admin.endpoint";
import moment from "moment/moment";
import ReactDatetime from "react-datetime";
// Somewhere in your `index.ts`:
setChonkyDefaults({ iconComponent: ChonkyIconFA });

const fetchS3BucketContents = async (bucket, prefix) => {
  try {
    return await s3Client
      .listObjectsV2({
        Bucket: bucket,
        Delimiter: "/",
        Prefix: prefix !== "/" ? prefix : "",
      })
      .then((response) => {
        console.log(response);
        const chonkyFiles = [];
        const s3Objects = response.Contents;
        const s3Prefixes = response.CommonPrefixes;

        if (s3Objects) {
          s3Objects.map((object) => {
            if (object.Size > 0) {
              chonkyFiles.push({
                id: object.Key,
                name: path.basename(object.Key),
                modDate: object.LastModified,
                size: object.Size,
              });
            }
          });
        }

        if (s3Prefixes) {
          chonkyFiles.push(
            ...s3Prefixes.map((prefix) => ({
              id: prefix.Prefix,
              name: path.basename(prefix.Prefix),
              isDir: true,
            }))
          );
        }

        return chonkyFiles;
      });
  } catch (e) {
    console.log(e);
  }
};

export default function StationFm(props) {
  const sid = sessionStorage.getItem("station_file_manage_id");
  const sname = sessionStorage.getItem("station_file_manage_name");
  const base_path = "station_files/" + sid + "/";
  const [uploadModal, setUploadModal] = useState(false);
  const [error, setError] = useState(null);

  const [folderPrefix, setKeyPrefix] = useState(base_path);
  const [files, setFiles] = useState([]);
  const [upfile, setUpfile] = useState(null);
  const [createFolderModal, setCreateFolderModal] = useState(false);
  const [folderName, setFolderName] = useState("");
  const myFileActions = [
    ChonkyActions.OpenFiles,
    ChonkyActions.DownloadFiles,
    ChonkyActions.MoveFiles,
    ChonkyActions.DeleteFiles,
    ChonkyActions.UploadFiles,
    ChonkyActions.CreateFolder,
  ];

  const create_folder = async () => {
    return s3Client
      .putObject({
        Bucket: BUCKET_NAME,
        Key: folderPrefix + folderName + "/",
      })
      .then((r) => {
        console.log(r);
        setCreateFolderModal(false);
        refreshFolder();
      });
  };

  useEffect(() => {
    fetchS3BucketContents(BUCKET_NAME, folderPrefix)
      .then(setFiles)
      .catch((error) => setError(error.message));
  }, [folderPrefix, setFiles]);

  const refreshFolder = () => {
    fetchS3BucketContents(BUCKET_NAME, folderPrefix)
      .then(setFiles)
      .catch((error) => setError(error.message));
  };

  const folderChain = React.useMemo(() => {
    let folderChain = [];
    if (folderPrefix === "/") {
      folderChain = [];
    } else {
      let currentPrefix = "";
      folderChain = folderPrefix
        .replace(/\/*$/, "")
        .split("/")
        .map((prefixPart) => {
          currentPrefix = currentPrefix
            ? path.join(currentPrefix, prefixPart)
            : prefixPart;
          return {
            id: currentPrefix,
            name: prefixPart,
            isDir: true,
          };
        });
    }
    folderChain.unshift({
      id: "/",
      name: BUCKET_NAME,
      isDir: true,
    });
    return folderChain;
  }, [folderPrefix]);

  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };
  let newFileValue = {
    stationId: sid,
    stationName: sname,
  };

  const [NewFileObject, setNewFileObject] = useState({});

  useEffect(() => {
    console.log(NewFileObject);
  }, [NewFileObject]);

  const handleNewFile = (typeofObj, value) => {
    newFileValue[typeofObj] = value;
    setNewFileObject((NewFileObject) => ({
      ...NewFileObject,
      ...newFileValue,
    }));
    console.log(NewFileObject);
  };
  const [
    { data: addFile, loading: addFileLoading, error: addFileError },
    addFileFunc,
  ] = adminRoutes(
    {
      url: adminEndpoints.add_file.path,
      method: adminEndpoints.add_file.method,
      headers: config.header,
      data: NewFileObject,
    },
    { manual: true }
  );

  const uploadToS3 = async (bucket, file) => {
    console.log(file.name);
    try {
      return await s3Client
        .send(
          new PutObjectCommand({
            Bucket: bucket,
            Key: folderPrefix + file.name,
            Body: file,
          })
        )
        .then((response) => {
          refreshFolder();
          addFileFunc();
          console.log(response);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleDown = async (param, filename) => {
    const file = await s3Client.send(new GetObjectCommand(param));

    fileDownload(await file.Body.transformToByteArray(), filename);
  };
  const handleDelete = async (param) => {
    await s3Client.send(new DeleteObjectCommand(param));
  };
  const handleFileAction = useCallback(
    (data) => {
      //for open file and folder and download file
      if (data.id === ChonkyActions.OpenFiles.id) {
        if (
          data.payload.targetFile &&
          !data.payload.targetFile.isDir &&
          data.payload.targetFile.size > 0
        ) {
          console.log("download file");
          const params = {
            Bucket: BUCKET_NAME,
            Key: data.payload.targetFile.id,
          };
          handleDown(params, data.payload.targetFile.name);

          return;
        }

        if (!data.payload.targetFile || !data.payload.targetFile.isDir) return;
        const newPrefix = `${data.payload.targetFile.id.replace(/\/*$/, "")}/`;
        console.log(`Key prefix: ${newPrefix}`);
        setKeyPrefix(newPrefix);
      }
      //for download file
      if (data.id === ChonkyActions.DownloadFiles.id) {
        console.log("download file dd");
        data.state.selectedFiles.forEach((fileId) => {
          if (fileId.isDir || fileId.size === 0) {
            console.log("download folder");
            return;
          } else {
            const params = {
              Bucket: BUCKET_NAME,
              Key: fileId.id,
            };
            handleDown(params, fileId.name);
          }
        });
      }
      //for download multiple files
      if (data.id === ChonkyActions.OpenSelection.id) {
        console.log("download file dd");
        data.state.selectedFiles.forEach((fileId) => {
          if (fileId.isDir || fileId.size === 0) {
            console.log("download folder");
            return;
          } else {
            const params = {
              Bucket: BUCKET_NAME,
              Key: fileId.id,
            };
            handleDown(params, fileId.name);
          }
        });
      }
      //for create folder
      if (data.id === ChonkyActions.CreateFolder.id) {
        console.log("create folder");
        setCreateFolderModal(true);
      }
      //for opening upload popup
      if (data.id === ChonkyActions.UploadFiles.id) {
        console.log("upload file");
        console.log(data);
        setUploadModal(true);
        console.log(folderPrefix);
      }
      //for deleting file
      if (data.id === ChonkyActions.DeleteFiles.id) {
        console.log("delete file");
        data.state.selectedFiles.forEach((fileId) => {
          if (fileId.isDir) {
            console.log("delete folder");
            const params = {
              Bucket: BUCKET_NAME,
              Key: fileId.id,
            };
            handleDelete(params).then((res) => {
              alert(
                "Please delete the files inside the folder first than folder will be deleted"
              );
              refreshFolder();
            });
          } else {
            const params = {
              Bucket: BUCKET_NAME,
              Key: fileId.id,
            };
            handleDelete(params).then(() => {
              refreshFolder();
            });
          }
        });
      }
      //TODO move FIles around on drag and drop
      if (data.id === ChonkyActions.MoveFiles.id) {
        console.log("move file");
        console.log("destination");
        console.log(data.payload.destination);
        console.log("files");
        console.log(files);
        console.log("selectedfiles");
        console.log(data.payload.selectedFiles);
      }
    },
    [setKeyPrefix]
  );

  return (
    <>
      <SimpleHeader name="Station File Manager" parentName="Stations" />
      <Modal
        className="modal-dialog-centered"
        isOpen={createFolderModal}
        toggle={() => setCreateFolderModal(!createFolderModal)}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="createFolderModalLabel">
            Create Folder
          </h5>
          <Button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setCreateFolderModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </Button>
        </div>

        <div className="modal-body">
          <div>
            <Label htmlFor={"newFolderName"}>
              New Folder Name (Without Spaces)
            </Label>
            <Input
              id={"newFolderName"}
              placeholder={"Folder Name"}
              type={"text"}
              name={"folderName"}
              onChange={(e) => {
                setFolderName(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => setCreateFolderModal(false)}
          >
            Close
          </Button>
          <Button
            color="primary"
            type="button"
            onClick={() => {
              create_folder();
            }}
          >
            Save changes
          </Button>
        </div>
      </Modal>

      <Modal
        className="modal-dialog-centered"
        isOpen={uploadModal}
        toggle={() => setUploadModal(!uploadModal)}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="uploadModalLabel">
            File Upload
          </h5>
          <Button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setUploadModal(false)}
          >
            <span aria-hidden={true}>×</span>
          </Button>
        </div>

        <div className="modal-body">
          <FormGroup>
            <FormGroup>
              <label className="form-control-label" htmlFor="input-date">
                Expiry Date
              </label>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-calendar-grid-58" />
                  </InputGroupText>
                </InputGroupAddon>
                <ReactDatetime
                  inputProps={{
                    placeholder: "Date Picker Here",
                  }}
                  value={moment(new Date()).format("YYYY-MM-DD")}
                  timeFormat={false}
                  name={"expiry-date"}
                  onChange={(e) => {
                    handleNewFile("expiry", moment(e).format("YYYY-MM-DD"));
                  }}
                  required
                />
              </InputGroup>
            </FormGroup>
          </FormGroup>
          <FormGroup>
            <Label htmlFor={"up"}>Upload File</Label>
            <Input
              type={"file"}
              name={"up"}
              multiple={false}
              onChange={(e) => {
                let f = e.target.files;
                setUpfile(f);
                console.log(f[0]);
                handleNewFile("fileName", f[0].name);
                handleNewFile("path", folderPrefix);
              }}
            />
          </FormGroup>
        </div>
        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={() => setUploadModal(false)}
          >
            Close
          </Button>
          <Button
            color="primary"
            type="button"
            onClick={() => {
              uploadToS3(BUCKET_NAME, upfile[0]).then((r) => {
                setUploadModal(false);
              });
            }}
          >
            Save changes
          </Button>
        </div>
      </Modal>

      <Container className={"mt--6"} fluid>
        <Card>
          <CardHeader className={"justify-content-between d-flex "}>
            <div>
              <h3>{sname && sname} Station FM</h3>
            </div>
            <div>
              <Button
                onClick={(e) => {
                  setKeyPrefix(base_path);
                }}
                variant={"contained"}
                color={"primary"}
              >
                <i className={"fa fa-arrow-left mx-2"}></i>
                Home
              </Button>
            </div>

            {/* <div>
              <Button
                onClick={(e) => {
                  // setKeyPrefix("station/639c54a47ae8c209408692db/");
                  handleDown();
                }}
              >
                Download All Files
              </Button>
            </div>*/}
          </CardHeader>
          <CardBody className={"h-100 vh-100"}>
            <FullFileBrowser
              files={files}
              // folderChain={folderChain}
              fileActions={myFileActions}
              onFileAction={handleFileAction}
            ></FullFileBrowser>
          </CardBody>
        </Card>
      </Container>
    </>
  );
}
