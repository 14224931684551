import React from "react";
import { Card, CardBody } from "reactstrap";

function TankCard({ color, product, capacity, tankNumber, tankData }) {
  return (
    <Card
      style={{
        border: `5px solid ${color}`,
        minHeight: "8.7rem",
      }}
    >
      <CardBody className="p-0">
        <div
          className="text-center py-1 font-weight-bold"
          style={{ background: `${color}` }}
        >
          Tank {tankNumber}
        </div>
        <hr className="p-0 m-0" />
        <div className="p-2">
          <div className="text-capitalize text-center">Product: {product}</div>
          <div className="text-center">Capacity: {capacity}</div>
          <div className="text-center font-weight-bold">
            {(tankData && tankData[tankNumber] && (
              <span> Current Volume: {tankData[tankNumber].values.Volume}</span>
            )) || (
              <span className="text-danger">
                Current Volume: No Data Available
              </span>
            )}
          </div>
          <div className="text-center font-weight-bold">
            {(tankData && tankData[tankNumber] && (
              <span>Report Time: {tankData[tankNumber]?.date}</span>
            )) || (
              <span className="text-danger">
                Report Time : No Data Available
              </span>
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

export default TankCard;
