import React, { useState } from "react";
// react plugin that prints a given react component
// react component for creating dynamic tables
// react component used to create sweet alerts
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import Select from "react-select";
import { adminRoutes } from "../../../endpoints/base.endpoint";
import * as adminEndpoints from "../../../endpoints/admin.endpoint";

function OTAStation() {
  let OTAObjectValue = {
    OTAStatus: false,
  };
  const [newOTAObject, setNewOTAObject] = useState(OTAObjectValue);
  const [isVerified, setIsVerified] = useState(false);
  const [inputErrors, setInputErrors] = useState({
    fileNameError: "",
  });

  let Routes;
  let endpoints;
  Routes = adminRoutes;
  endpoints = adminEndpoints;

  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  const [
    { data: getStation, loading: getStationLoading, error: getStationError },
  ] = Routes({
    url: endpoints.list_station.path,
    method: endpoints.list_station.method,
    headers: config.header,
  });

  let StationList = [];

  if (getStation) {
    // FILTER STATIONS WITH GATEWAY (false = with gateway)
    const withGatewayStaions = getStation.data.filter(
      (station) => station.gateway === true,
    );
    // transform filtered stations list to set in select input field
    withGatewayStaions.map((data, idx) =>
      StationList.push({
        value: data,
        label: `${data.stationName} - ${data.stationAddress}`,
      }),
    );
  }

  const [
    {
      data: getOTAFileList,
      loading: getOTAFileListLoading,
      error: getOTAFileListError,
    },
  ] = adminRoutes({
    url: endpoints.OTAFile_list.path,
    method: endpoints.OTAFile_list.method,
    headers: config.header,
  });

  let OTAList = [];

  if (getOTAFileList) {
    const newList = getOTAFileList.map((data) => {
      return { fileName: data };
    });
    newList.map((data) => OTAList.push({ value: data, label: data.fileName }));
  }

  const handleNewOTA = (typeofObj, value) => {
    setNewOTAObject((newOTAObject) => ({
      ...newOTAObject,
      [typeofObj]: value,
    }));

    if (typeofObj === "fileName") {
      if (value.length > 0) {
        setInputErrors({ ...inputErrors, fileNameError: "" });
      } else {
        setInputErrors({
          ...inputErrors,
          fileNameError: "File name required!",
        });
      }
    }
  };

  const [{ loading: updateOTALoading, error: updateOTAError }, updateOTA] =
    adminRoutes(
      {
        url: endpoints.OTA_update.path,
        method: endpoints.OTA_update.method,
        headers: config.header,
        data: newOTAObject,
      },
      { manual: true },
    );

  function handleOTASubmit() {
    const errors = {};

    if (newOTAObject.fileName === "") {
      errors.fileNameError = "File name required!";
    }

    setInputErrors(errors);
    updateOTA().then((res) => {
      window.location.href = "/admin/station-list";
    });
  }

  return (
    <>
      <SimpleHeader name="Station OTA" parentName="Station" />
      <Container className="mt--6" fluid>
        <Card>
          {/* <CardHeader className="bg-transparent">
            <h3 className="mb-0 ">Station OTA</h3>
          </CardHeader> */}
          <CardBody>
            <Form>
              <h6 className="heading-small text-muted mb-4">
                Station Information
              </h6>
              <div className="px-lg-4">
                <Row>
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="stationList"
                      >
                        Select OTA Station
                      </label>
                      <Select
                        options={StationList}
                        id={"stationList"}
                        onChange={(e) => {
                          handleNewOTA("stationID", e.value._id);
                          handleNewOTA("stationName", e.value.stationName);
                          handleNewOTA("deviceID", e.value.deviceID);
                          setIsVerified(false);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  {newOTAObject.stationID && (
                    <>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            htmlFor="deviceId"
                            className="form-control-label"
                          >
                            Device Id
                          </label>
                          <Input
                            id="deviceId"
                            rows="6"
                            type={"text"}
                            defaultValue={newOTAObject && newOTAObject.deviceID}
                            value={newOTAObject && newOTAObject.deviceID}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <label
                          className={
                            isVerified ? "text-success" : "text-danger"
                          }
                        >
                          {isVerified
                            ? "Verified"
                            : "Verify device id to submit form"}
                        </label>
                        <div>
                          <Button
                            color="default"
                            onClick={() => setIsVerified(true)}
                          >
                            Verify
                          </Button>
                        </div>
                      </Col>
                    </>
                  )}
                  <Col lg="4">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="input-fileName"
                      >
                        File Name
                      </label>
                      <Select
                        options={OTAList}
                        id={"input-fileName"}
                        onChange={(e) => {
                          handleNewOTA("fileName", e.value.fileName);
                        }}
                        required
                      />
                      {inputErrors.fileNameError && (
                        <div className="text-danger">
                          {inputErrors.fileNameError}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <div
                        className="mb-2"
                        style={{ fontSize: "0.875rem", fontWeight: "600" }}
                      >
                        OTA
                      </div>
                      <label className="custom-toggle">
                        <input
                          type="checkbox"
                          checked={newOTAObject.OTAStatus}
                          onChange={(e) => {
                            handleNewOTA("OTAStatus", e.target.checked);
                          }}
                        />
                        <span className="custom-toggle-slider rounded-circle" />
                      </label>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </Form>
          </CardBody>
          <CardFooter>
            <Button
              color="default"
              onClick={handleOTASubmit}
              disabled={!isVerified || updateOTALoading}
            >
              Submit
            </Button>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
}

export default OTAStation;
