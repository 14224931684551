import { Button, Col, FormGroup, Input } from "reactstrap";

function CmdAndCmdTimeInput({ cmdSet, timeSet ,index}) {
  const handleCmdChange = (e) => {
    cmdSet(e.target.value);
  };
  const handleCmdTimeSet = (e) => {
    timeSet(e.target.value,index);
  };

  return (
    <>
      <Col lg="6">
        <FormGroup>
          <label className="form-control-label" htmlFor="cmd">
            Command {index}
          </label>
          <Input type="select" id="cmd" onChange={handleCmdChange}>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </Input>
        </FormGroup>
      </Col>
      <Col lg="6">
        <FormGroup>
          <label className="form-control-label" htmlFor="cmdTime">
            Command Time
          </label>
          <div className="d-flex">
            <Input type="time" id="cmdTime" onChange={handleCmdTimeSet} />

          </div>
        </FormGroup>
      </Col>
    </>
  );
}

export default CmdAndCmdTimeInput;
