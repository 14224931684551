import React, { useEffect, useState } from "react";
// react plugin that prints a given react component
import ReactToPrint from "react-to-print";
// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
// react component used to create sweet alerts
import ReactBSAlert from "react-bootstrap-sweetalert";
// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { adminRoutes } from "../../../endpoints/base.endpoint";
import * as adminEndpoints from "../../../endpoints/admin.endpoint";

const pagination = paginationFactory({
  page: 1,
  sizePerPage: 100,
  alwaysShowAllBtns: true,
  showTotal: true,
  withFirstAndLast: false,
  sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
    <div className="dataTables_length" id="datatable-basic_length">
      <label>
        Show
        {
          <select
            name="datatable-basic_length"
            aria-controls="datatable-basic"
            className="form-control form-control-sm"
            defaultValue={"100"}
            onChange={(e) => onSizePerPageChange(e.target.value)}
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        }
        entries.
      </label>
    </div>
  ),
});

const { SearchBar } = Search;

function JobberList() {
  const [alert, setAlert] = React.useState(null);
  const componentRef = React.useRef(null);
  // this function will copy to clipboard an entire table,
  // so you can paste it inside an excel or csv file
  const copyToClipboardAsTable = (el) => {
    var body = document.body,
      range,
      sel;
    if (document.createRange && window.getSelection) {
      range = document.createRange();
      sel = window.getSelection();
      sel.removeAllRanges();
      try {
        range.selectNodeContents(el);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(el);
        sel.addRange(range);
      }
      document.execCommand("copy");
    } else if (body.createTextRange) {
      range = body.createTextRange();
      range.moveToElementText(el);
      range.select();
      range.execCommand("Copy");
    }
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Good job!"
        onConfirm={() => setAlert(null)}
        onCancel={() => setAlert(null)}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Copied to clipboard!
      </ReactBSAlert>
    );
  };

  const [NewJobberModel, setNewJobberModel] = useState(false);

  const [NewJobberObject, setNewJobberObject] = useState();

  function closeModal() {
    if (NewJobberModel) {
      setNewJobberModel(false);
    } else {
      setNewJobberModel(true);
    }
  }

  let newJobberValue = {};

  useEffect(() => {
    console.log(NewJobberObject);
  }, [NewJobberObject]);

  const handleNewJO = (typeofObj, value) => {
    newJobberValue[typeofObj] = value;
    setNewJobberObject((NewJobberObject) => ({
      ...NewJobberObject,
      ...newJobberValue,
    }));
    console.log(NewJobberObject);
  };

  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  const [
    { data: getJobber, loading: getJobberLoading, error: getJobberError },
    refreshJobber,
  ] = adminRoutes(
    {
      url: adminEndpoints.list_jobber.path,
      method: adminEndpoints.list_jobber.method,
      headers: config.header,
    },
    { manual: false }
  );

  // add edit & detail button in jobber list
  if (getJobber) {
    console.log(getJobber);
    getJobber.map(
      (data, idx) =>
        (data.btn = (
          <>
            <a
              href={"./jobber-edit"}
              className="btn btn-warning  btn-sm  d-print-none"
              onClick={(e) => {
                sessionStorage.setItem("jobber_edit_id", data._id);
              }}
            >
              Edit
            </a>
            <a
              href={"./jobber-details"}
              className="btn btn-success  btn-sm  d-print-none"
              onClick={(e) =>
                sessionStorage.setItem("jobber_manage_id", data._id)
              }
            >
              Details
            </a>
          </>
        ))
    ); //to add edit and details button in the table
  }
  useEffect(() => {
    console.log(getJobber);
  }, [getJobber]);
  const [
    { loading: createJobberLoading, error: createJobberError },
    createJobber,
  ] = adminRoutes(
    {
      url: adminEndpoints.create_jobber.path,
      method: adminEndpoints.create_jobber.method,
      headers: config.header,
      data: NewJobberObject,
    },
    { manual: true }
  );
  function handleNewJobberSubmit() {
    closeModal();
    createJobber().then((r) => {
      console.log(r);
      refreshJobber();
    });
  }
  return (
    <>
      {alert}
      <SimpleHeader name="Jobber List" parentName="Jobber" />
      <Modal
        className="modal-dialog-centered w-100 h-100"
        backdrop={false}
        size="xl"
        centered={true}
        scrollable={true}
        isOpen={NewJobberModel}
        toggle={closeModal}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Create Jobber
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <Form>
            <h6 className="heading-small text-muted mb-4">
              Jobber Information
            </h6>
            <div className="px-lg-4">
              <Row>
                <Col lg="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-jobber-name"
                    >
                      Jobber Name
                    </label>
                    <Input
                      id="input-jobber-name"
                      type={"text"}
                      placeholder={"Jobber Name"}
                      onChange={(e) => {
                        handleNewJO("name", e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-jobber-email"
                    >
                      Jobber Email
                    </label>
                    <Input
                      id="input-jobber-email"
                      type={"text"}
                      placeholder={"Jobber Email"}
                      onChange={(e) => {
                        handleNewJO("email", e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-jobber-Password"
                    >
                      Password
                    </label>
                    <Input
                      id="input-jobber-Password"
                      type={"text"}
                      placeholder={"Password"}
                      onChange={(e) => {
                        handleNewJO("password", e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>{" "}
                <Col lg="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-jobber-phone"
                    >
                      Jobber Phone
                    </label>
                    <Input
                      id="input-jobber-phone"
                      type={"tel"}
                      placeholder={"Jobber phone"}
                      onChange={(e) => {
                        handleNewJO("phone", e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-company-name"
                    >
                      Company Name
                    </label>
                    <Input
                      id="input-company-name"
                      type={"text"}
                      placeholder={"Company Name"}
                      onChange={(e) => {
                        handleNewJO("companyName", e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="input-company-address"
                    >
                      Company Address
                    </label>
                    <Input
                      id="input-company-address"
                      type={"text"}
                      placeholder={"Company Address"}
                      onChange={(e) => {
                        handleNewJO("companyAddress", e.target.value);
                      }}
                      maxLength={"50"}
                    />
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="company-city"
                    >
                      Company City
                    </label>
                    <Input
                      id="company-city"
                      rows="6"
                      type={"text"}
                      placeholder={"Company City"}
                      onChange={(e) => {
                        handleNewJO("companyCity", e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="company-state"
                    >
                      Company State
                    </label>
                    <Input
                      id="company-state"
                      rows="6"
                      type={"text"}
                      placeholder={"Company State"}
                      onChange={(e) => {
                        handleNewJO("companyState", e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <label
                      className="form-control-label"
                      htmlFor="company-country"
                    >
                      Company Country
                    </label>
                    <Input
                      id="company-country"
                      rows="6"
                      type={"text"}
                      placeholder={"Company Country"}
                      onChange={(e) => {
                        handleNewJO("companyCountry", e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col lg="4">
                  <FormGroup>
                    <label className="form-control-label" htmlFor="company-zip">
                      Company Zip
                    </label>
                    <Input
                      id="company-zip"
                      rows="6"
                      type={"text"}
                      placeholder={"Company Zip"}
                      onChange={(e) => {
                        handleNewJO("companyZip", e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </Form>
        </div>
        <div className="modal-footer">
          <Button color="default" onClick={handleNewJobberSubmit}>
            Add Jobber
          </Button>{" "}
          <Button onClick={closeModal}>Cancel</Button>
        </div>
      </Modal>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              {/* <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Jobber List</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button color="default" onClick={closeModal} size="md">
                      New Jobber
                    </Button>
                  </Col>
                </Row>
              </CardHeader> */}

              {getJobber && (
                <ToolkitProvider
                  data={getJobber}
                  keyField="name"
                  columns={[
                    {
                      dataField: "name",
                      text: "Name",
                      sort: true,
                    },
                    {
                      dataField: "email",
                      text: "Email",
                      sort: true,
                    },
                    {
                      dataField: "phone",
                      text: "phone",
                      sort: true,
                    },
                    {
                      dataField: "btn",
                      text: "Action",
                      sort: false,
                      headerStyle: { textAlign: "center" },
                      style: {
                        textAlign: "center",
                      },
                    },
                  ]}
                  search
                >
                  {(props) => (
                    <div className="py-4 px-4 table-responsive">
                      <Container fluid>
                        <Row>
                          <Col xs={12} sm={9}>
                            <ButtonGroup className="float-left">
                              <Button
                                className="buttons-copy buttons-html5"
                                color="primary"
                                size="sm"
                                id="copy-tooltip"
                                onClick={() =>
                                  copyToClipboardAsTable(
                                    document.getElementById("react-bs-table")
                                  )
                                }
                              >
                                <span>Copy</span>
                              </Button>
                              <ReactToPrint
                                trigger={() => (
                                  <Button
                                    color="primary"
                                    size="sm"
                                    className="buttons-copy buttons-html5"
                                    id="print-tooltip"
                                  >
                                    Print
                                  </Button>
                                )}
                                content={() => componentRef.current}
                              />
                            </ButtonGroup>
                            <UncontrolledTooltip
                              placement="top"
                              target="print-tooltip"
                            >
                              This will open a print page with the visible rows
                              of the table.
                            </UncontrolledTooltip>
                            <UncontrolledTooltip
                              placement="top"
                              target="copy-tooltip"
                            >
                              This will copy to your clipboard the visible rows
                              of the table.
                            </UncontrolledTooltip>

                            <div
                              id="datatable-basic_filter"
                              className="dataTables_filter px-4 pb-1"
                            >
                              <label>
                                Search:
                                <SearchBar
                                  className="form-control-sm"
                                  placeholder=""
                                  {...props.searchProps}
                                />
                              </label>
                            </div>
                          </Col>
                          <Col xs={12} sm={3}>
                            <Button
                              color="default"
                              onClick={closeModal}
                              size="md"
                              className="float-right p-2"
                            >
                              New Jobber
                            </Button>
                          </Col>
                        </Row>
                      </Container>
                      <BootstrapTable
                        ref={componentRef}
                        {...props.baseProps}
                        bootstrap4={true}
                        pagination={pagination}
                        bordered={true}
                        striped
                        hover
                        condensed
                        id="react-bs-table"
                      />
                    </div>
                  )}
                </ToolkitProvider>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}
export default JobberList;
