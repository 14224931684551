import { useEffect, useState } from "react";
// react plugin that prints a given react component
// react component for creating dynamic tables
// react component used to create sweet alerts
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Container,
  Form,
  FormGroup,
  Row,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
// work order component
import Select from "react-select";
import * as adminEndpoints from "../../../endpoints/admin.endpoint";
import { adminRoutes } from "../../../endpoints/base.endpoint";

function VendorEngineerAssignManager() {
  let newEngineerAssignValue = {};
  const [newEngineerAssignObject, setNewEngineerAssignObject] = useState();
  const [assignedEngineer, setAssignedEngineer] = useState([]);

  useEffect(() => {
    console.log(newEngineerAssignObject);
  }, [newEngineerAssignObject]);

  const handleEngineerAssign = (typeofObj, value) => {
    newEngineerAssignValue[typeofObj] = value;
    setNewEngineerAssignObject((newEngineerAssignObject) => ({
      ...newEngineerAssignObject,
      ...newEngineerAssignValue,
    }));
    console.log(newEngineerAssignObject);
  };

  let Routes = adminRoutes;
  let endpoints = adminEndpoints;

  const config = {
    header: {
      "Access-Control-Allow-Origin": "*",
      authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };

  // VENDOR MANAGER LIST API
  const [
    {
      data: getVendorManagers,
      loading: getVendorManagersLoading,
      error: getVendorManagersError,
    },
  ] = Routes(
    {
      url: endpoints.list_managers.path,
      method: endpoints.list_managers.method,
      headers: config.header,
    },
    { manual: false }
  );

  let ManagerList = [];

  if (getVendorManagers) {
    getVendorManagers.map((data, idx) =>
      ManagerList.push({ value: data, label: data.name })
    );
  }

  // VENDOR ENGINEER LIST API
  const [
    {
      data: getVendorEngieers,
      loading: getVendorEngineersLoading,
      error: getVendorEngineersError,
    },
  ] = Routes({
    url: endpoints.list_employees.path,
    method: endpoints.list_employees.method,
    headers: config.header,
  });

  let vendorEngineerList = [];
  console.log(getVendorEngieers);
  if (getVendorEngieers) {
    getVendorEngieers.map((data, idx) =>
      vendorEngineerList.push({ value: data, label: data.name })
    );
  }

  useEffect(() => {
    if (newEngineerAssignObject) {
      const selectedManager = ManagerList.find(
        (manager) => manager.value._id === newEngineerAssignObject.mid
      );

      const foundEngineer = [];

      if (
        selectedManager &&
        selectedManager.value &&
        selectedManager.value.engineers
      ) {
        for (let i = 0; i < selectedManager.value.engineers.length; i++) {
          for (let j = 0; j < vendorEngineerList.length; j++) {
            if (
              vendorEngineerList[j].value._id ===
              selectedManager.value.engineers[i]
            ) {
              foundEngineer.push(vendorEngineerList[j]);
            }
          }
        }
        if (foundEngineer.length > 0) {
          setAssignedEngineer(foundEngineer);
        } else {
          setAssignedEngineer([]);
        }
      }
    }
  }, [newEngineerAssignObject]);

  // ASSIGN VENDOR ENGINEER TO MANAGER API
  const [
    { loading: assignEngineerLoading, error: assignEngineerError },
    assignVendorEngineer,
  ] = adminRoutes(
    {
      url: endpoints.assign_vendor_engineer.path,
      method: endpoints.assign_vendor_engineer.method,
      headers: config.header,
      data: newEngineerAssignObject,
    },
    { manual: true }
  );

  function handleNewEngineerAssignSubmit() {
    assignVendorEngineer().then((res) => {
      window.location.href = "/admin/employee-list";
      console.log("redirect");
      console.log(res);
    });
  }

  return (
    <>
      <SimpleHeader
        name="Assign Vendor Engineer To Manager"
        parentName="Vendor"
      />
      <Container className="mt--6" fluid>
        <Card>
          {/* <CardHeader className="bg-transparent">
            <h3 className="mb-0 ">Assign Vendor Engineer To Manager</h3>
          </CardHeader> */}
          <CardBody>
            <Form>
              <h6 className="heading-small text-muted mb-4">
                Vendor Manager & Engineer Information
              </h6>
              <div className="px-lg-4">
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="vendorManagerId"
                      >
                        Vendor Manager to Assign
                      </label>
                      <Select
                        options={ManagerList}
                        id={"vendorManagerId"}
                        onChange={(e) => {
                          handleEngineerAssign("mid", e.value._id);
                        }}
                      />
                    </FormGroup>
                    {assignedEngineer.length > 0 && (
                      <table className="w-100">
                        <thead>
                          <tr>
                            <th colSpan={3} className="text-center border py-2">
                              Assgined Engineer
                            </th>
                          </tr>
                          <tr>
                            <th className="border text-center px-5">Name</th>
                            <th className="border text-center px-5">Email</th>
                            <th className="border text-center px-5">Phone</th>
                          </tr>
                        </thead>
                        <tbody>
                          {assignedEngineer.map((data, idx) => (
                            <tr key={idx}>
                              <td className="border text-center px-5">
                                {data.value.name}
                              </td>
                              <td className="border text-center px-5">
                                {data.value.email}
                              </td>
                              <td className="border text-center px-5">
                                {data.value.phone}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )}
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label
                        className="form-control-label"
                        htmlFor="vendorEngineer"
                      >
                        Vendor Engineer to Assign
                      </label>
                      <Select
                        options={vendorEngineerList}
                        id={"vendorEngineer"}
                        onChange={(e) => {
                          handleEngineerAssign("eid", e.value._id);
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </Form>
          </CardBody>
          <CardFooter>
            <Button color="default" onClick={handleNewEngineerAssignSubmit}>
              Assign Vendor Engineer
            </Button>
          </CardFooter>
        </Card>
      </Container>
    </>
  );
}

export default VendorEngineerAssignManager;
