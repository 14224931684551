import React from "react";
import { Col } from "reactstrap";

function DisabledCheckbox({title, isChecked }) {
  return (
    <>
      <Col sm="6" md="4" lg="3">
        <label
          className="mb-2"
          style={{ fontSize: "0.875rem", fontWeight: "600" }}
        >
          {title}
        </label>{" "}
        <input
          type="checkbox"
          defaultChecked={isChecked}
          disabled
        />
      </Col>
    </>
  );
}

export default DisabledCheckbox;
